import React, { useState, useEffect } from 'react';

const CardReader = ({ startChars, endChars, onCardRead, setCode, buy }) => {
    const [cardCode, setCardCode] = useState('');
    const [readingCard, setReadingCard] = useState(false);

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (["Shift", "Control", "Alt", "Meta", "CapsLock", "Tab", "Escape"].includes(event.key)) {
                return;
            }

            if (!readingCard && startChars.includes(event.key)) {
                setReadingCard(true);
                setCardCode(event.key);
                setCode(event.key)
                
            } else if (readingCard) {
                let newCardCode = cardCode + event.key;
                setCardCode(newCardCode);
                setCode(newCardCode)

                if (endChars.includes(event.key)) {
                    setCode(newCardCode.slice(1, -1))
                    buy()
                    onCardRead(newCardCode);
                    setReadingCard(false);
                    setCardCode('');
                }
            }
        };

        document.addEventListener('keydown', handleKeyDown);

        // Cleanup listener when component unmounts
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [readingCard, cardCode, startChars, endChars, onCardRead]);

    return (
        <div>
            {/* <div>Card code: {cardCode }</div> */}
        </div>
    );
};

export default CardReader;

// Usage example
/*
<CardReader
    startChars={['%', ';']}
    endChars={['?', '!']}
    onCardRead={(code) => console.log('Card Code:', code)}
/>
*/