import React, { useEffect, useState } from 'react';
import url from '../../../url ';
// import SelectSearch from 'react-select-search';
// import './SelectSearch.css'


function SelectSearch1(props) {
  const [selectedValue, setSelectedValue] = useState('');
  const domin = "https://m.tlushbot.co.il";
  const [filter, setFilter] = useState("a")
  const [options, setOptions] = useState({})
  
  const req = async () => {
    try {
      let res = await fetch(`${url}/admin/find-prams-for-colmun/${props.table}/${props.colmun}?filter=${filter}`, {
        credentials: 'include'
      });
      let data = await res.json();
      let obj = convertArrayToObject(data);
      console.log(obj);
      setOptions(obj);
      return data
    }
    catch (err) {
      console.log(err);
    }
  }
  useEffect(() => {
    let data = req();

  }, [filter])
  return (
    <div className='SelectSearch'>
      {/* <label htmlFor='nn'>{props.name}</label>
      {options.length >= 0 &&

        <SelectSearch
        id='nn'
          renderValue={(valueProps) => {
            setFilter(valueProps.value)
            return <input {...valueProps} />
          }}
          options={options}
          value={selectedValue}
          onChange={(value) => {
            setSelectedValue(value)
            props.hndel(value, props.raelName )
            console.log(value);
          }}
          search
          
          placeholder="בחר אופציה או הזן טקסט"
        /> */}
      {/* } */}
      {/* <p>הערך שנבחר או הטקסט שהוזן: {selectedValue}</p> */}
    </div>
  );
}
function convertArrayToObject(arr) {
  // יצירת מערך ריק לכל האובייקטים
  let result = [];

  // עבר בכל תת מערך שבמערך הנתון והמרתו לאובייקט
  for (let i = 0; i < arr.length; i++) {
    let subArray = arr[i];
    let name = subArray[0].trim(); // הסרת רווחים משני הצדדים של השם
    let value = subArray[1];

    // יצירת אובייקט חדש והוספתו למערך
    result.push({ name, value });
  }

  return result;
}
export default SelectSearch1;
