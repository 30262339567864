import "../Add.css";
// import { set } from "mongoose";

export default function MultySelect(props) {
    const { options, hndel, raelName, required } = props;
    
    
    function handleCheck(e) {
        const { name, checked } = e.target;
        const checkes = options.filter((option) => {
            if (String(option.value) === name) {
                option.checked = checked;
            }
            if (option.checked) {
                return true;
            }
            return false;
        }
        );
        hndel(checkes.map((option) => option.value), raelName);
    }

    return (
        <div>
            {options.map((option, ind) => {
               return <div key={option.value}> 
                    <label htmlFor={option.value} >{option.label}</label>
                    <input type="checkbox" name={option.value}  onChange={handleCheck} defaultValue={option.checked} checked={option.checked} required={required}/>
                </div>
            })}
        </div>

    );
}