import { useEffect, useState } from "react";
import "./storFamiliar.css";
import { useParams } from "react-router-dom";
import AutoHideAlert from "../alrt";
import url from "../url ";
import { useRef } from "react";
import { Checkbox } from "@mui/material";
export function StorFamiliar(props) {
  const [clientData, setClientData] = useState("");
  const [Data, setData] = useState("");

  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [sum, setSum] = useState("");
  const [sum1, setSum1] = useState("");
  const [isAlrt, setIsAlrt] = useState(false);
  const [message, setmessage] = useState("");
  const [ok, setOk] = useState(false);
  const [note, setNote] = useState("")
  const [spiner, setSpiner] = useState(false);
  const [typeBuy, setTypeBuy] = useState('money')

  const { id } = useParams();

  const InputRef = useRef(null);


  useEffect(() => {

      clientData?.id && InputRef.current.focus();
    // }

  }, [Data, clientData?.id]);

  window.addEventListener("resize", () => {
    setIsMobile(window.innerWidth < 768);
  });


  useEffect(() => {
    const req = async () => {
      try {
        const response = await fetch(`${url}/store/${id}/getCardDatils`, {
          method: "post",
          credentials: 'include',
          headers: {
            "Content-Type": "application/json", // Specify the content type if sending JSON data.
          },
          body: JSON.stringify({
            card_code: props.id,
          }),
        });
        const data = await response.json();
        setClientData(data);
        setData(data)
      
      } catch (error) {
        console.error("An error occurred:", error);
      }
    };
    req();
  }, [id, props.id]);
  useEffect(() => {
    if (clientData === "") {
      return;
    }
    if (clientData?.sum_on_card.length > 0) {
      let ee = 0;
      for (let i of clientData.sum_on_card) {
        ee += parseFloat(i.sum);
      }
      console.log(ee);
      if (ee > sum) {
        ee = sum;
        setSum1(sum);
      } else {
        setSum1(ee);
      }
      let s = 0;
      console.log(parseFloat(sum1));
      let max = parseFloat(sum1) < 0 ? parseFloat(sum) : ee;
      if (isNaN(max)) {
        max = parseFloat(sum);
      }
      if (isNaN(max)) {
        return;
      }
      console.log(max);

      for (let i of clientData.sum_on_card) {
        // i.checked = true
        // set

        if (s >= max) {
          // break
          handleCheckboxChange(i.id, false);
        } else {
          handleCheckboxChange(i.id, true);
          s += parseFloat(i.sum);
        }
        // console.log(s);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sum]);
  useEffect(() => {
    let s = 0;
    // console.log( parseFloat(sum1));
    // let max= parseFloat(sum1)<0  ? parseFloat(sum) : parseFloat(sum1)
    // if( isNaN(max) ){
    //   max = parseFloat(sum)
    // }
    // if( isNaN(max) ){
    //  return
    // }
    // console.log(max);
    if (clientData === "") {
      return;
    }
    if (clientData?.sum_on_card.length > 0) {
      let max;
      sum1 === "" ? max = 0 : max  = parseFloat(sum1);
      console.log(max);
      for (let i of clientData.sum_on_card) {
        // i.checked = true
        // set

        if (s >= max) {
          // break
          handleCheckboxChange(i.id, false);
        } else {
          handleCheckboxChange(i.id, true);
          s += parseFloat(i.sum);
        }
        // console.log(s);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sum1]);
  const other = () => {
    let sumo = 0;
    for (let i in clientData.sum_on_card) {
      if (clientData.sum_on_card[i]?.checked) {
        sumo += parseFloat(clientData.sum_on_card[i].sum);
      }
    }
    if (!isNaN(parseFloat(sum1))) {
      sumo = parseFloat(sum1);
    }
    if (sum - sumo < 0) {
      return 0;
    }
    return sum - sumo;
  };
  const handleCheckboxChange = (id, bo, type='sum_on_card') => {
    setClientData((prevData) => {
      const updatedSumOnCard = prevData[type].map((obj) => {
        if (obj.id === id) {
          if (bo !== undefined) {
            return { ...obj, checked: bo };
          } else {
            return { ...obj, checked: !obj.checked };
          }
        }
        return obj;
      });
      return { ...prevData, [type]: updatedSumOnCard };
    });
  };

  const chergsID = () => {
    let arr = [];
    for (let i in clientData.sum_on_card) {
      if (clientData.sum_on_card[i]?.checked) {
        arr.push(clientData.sum_on_card[i].id);
      }
    }
    return arr;
  };
  const buy = async () => {
    setSpiner(true)
    let body;
    if (typeBuy === 'money') {
      body = {
        card_code: props.id,
        sum: parseFloat(sum),
        note : note,
        chergsID: chergsID(),
        sumFromCard : sum1 === "" ? 0 : parseFloat(sum1),
      }
    } else {
      body = {
        card_code: props.id,
        vouchersID: clientData.vouchers.filter(v => v.checked).map(v => v.id),
      }
    }
    try {
      const response = await fetch(`${url}/store/${id}/doUsege/${typeBuy}`, {
        method: "post",
        credentials: 'include',
        headers: {
          "Content-Type": "application/json", // Specify the content type if sending JSON data.
        },
        body: JSON.stringify(body),
      });
      const data = await response.json();
      setSpiner(false)
      setOk(false);
      if (data.status === "success") {
        // alert('הקניה הושלמה בהצלחה')
        setmessage("הקניה הושלמה בהצלחה");
        setIsAlrt(true);
        setTimeout(() => {
          setmessage("");
          setIsAlrt(false);
        }, 5000);
        // setSum("");
        // req();
        window.location.reload();
        // props.setisCode(false)
        return;
      } else {
        // alert('אירעה שגיאה נסה שוב')
        setmessage(data.error);
        setIsAlrt(true);
        setTimeout(() => {
          setmessage("");
          setIsAlrt(false);
        }, 5000);
      }
    } catch (e) {
      console.log(e);
    }
  };
  return (
    <div className="root">
      {isAlrt && <AutoHideAlert message={message} />}
      <div className="main" style={{ width: isMobile ? "70%" : "25%" }}>
        <h1> קהילות קארד</h1>
        {clientData?.id && (
          <>
            <div className="cardData">
              <h4>פרטי כרטיס</h4>
              <p>
                שם מועדון: {clientData["מועדון"]}
                <br />
                {clientData.name} {clientData.phone}
              </p>
            </div>
          <div className="cardData">
          <label htmlFor="cc">סוג טעינה</label>
          <br />
          <button 
          onClick={() => setTypeBuy('money')}
          style={{ 
            backgroundColor: typeBuy === 'money' ? "" : "white",
            color: typeBuy === 'money' ? "white" : "black",
            border: "1px solid black"
          }}>כסף</button>
          <button
          onClick={() => setTypeBuy('voucher')}
          style={{ 
            backgroundColor: typeBuy === 'voucher' ? "" : "white", 
            color: typeBuy === 'voucher' ? "white" : "black",
            border: "1px solid black"
          }}>שוברים</button>
          </div>
            {typeBuy === 'money' ? (
              <div>
              <div className="buy">
              <label>  הכנס את סכום הקניה הסופי  </label>
              <input
                type="number"
                ref={InputRef}
                value={sum}
                onChange={(e) =>e.target.value > -1 && setSum(e.target.value)}
              />

              {/* <label>מימוש מהכרטיס</label> */}
              {clientData.sum_on_card.length > 0 && (
                <div>
              <label htmlFor="vv">מימוש מהכרטיס</label>
              {/* <input type="checkbox" id="vv" checked={vv} onChange={(e)=> setvv(e.target.checked)}/>   */}
              <br />
              <label>סכום למימוש: </label>
              <input
                type="number"
                value={sum1}
                onChange={(e) =>e.target.value > -1 && clientData.sum_on_card && clientData.sum_on_card.length > 0 && setSum1(e.target.value)}
                style={{ width: "50%" }}
              />
              <div
                style={{
                  // display: "flex",
                  alignItems: "center",
                  border: "1px black solid",
                  margin: "auto",
                  width: "90%",
                  marginBottom: "5%",
                  borderRadius: "5px",
                }}
              >
                { clientData.sum_on_card.map((obj, Index) => {
                    return (
                      <div 
                      key={obj.id}
                      style={{ backgroundColor: obj.checked ? 'green' : 'red' }}
                      onClick={() => handleCheckboxChange(obj.id)}>
                        <br/>
                        <input
                          type="checkbox"
                          id={obj.id}
                          checked={obj?.checked}
                          style={{
                            marginRight: "8px",
                            width: "50%",
                            margin: "auto",
                          }}
                        />
                        <label
                          htmlFor={obj.id}
                          onClick={() => handleCheckboxChange(obj.id)}
                          style={{
                            marginRight: "8px",
                            width: "50%",
                            margin: "auto",
                            overflow : "auto"
                          }}
                        >
                          {obj.sum} 
                          <br/>
                          <span style={{fontSize :'10px'}}>הערות: {obj["הערות"]} 
                          <br/>
                          בתוקף עד:  {obj["בתוקף עד"]}</span>
                          
                        </label>
                        
                      </div>
                      
                    );
                  })}
              </div>

              <label>תשלום בצורה אחרת</label>
              <input type="number" value={other()} readOnly />
              </div>
              )}
              <label>פירוט </label>
              <input type="text" value={note} onChange={(e)=>setNote(e.target.value)}/>
              <button onClick={() => setOk(true)}>אישור</button>
            </div>
            <div className="bonus">
              <h4> הטבות לחברי {clientData["מועדון"]}</h4>
              <p>{clientData["הסכם"]}</p>
              {clientData.groups_store_arguments.forEach((i) => {
                 <p>{i}</p>
              })}
            </div>
            </div>
            ) : (
              <div>
                {clientData.vouchers.map((v) => {
                  return (
                    <div key={v.id}
                    className="voucher"
                    onClick={() => handleCheckboxChange(v.id, undefined, 'vouchers')}
                    style={{backgroundColor: v?.checked ? 'green' : 'red'}}
                    >
                      <div className="voucher-header">
                      <Checkbox checked={v?.checked | false}/>
                      <h4>{v.title}</h4>
                      </div>
                      <div className="voucher-details">
                      <p>הערת מנהל החנות:  {v.notesFromAdmin}</p>
                      <br/>
                      <p>הערת המועדון:  {v.notesFromClub}</p>
                      <br/>
                      <p>תוקף השובר:  {v.validUntil !== '31/12/99' ? v.validUntil : 'ללא הגבלה'}</p>
                      <br/>
                      <p>הגבלות:  {v.restrictions}</p>
                      <br/>
                      <p>מידע כללי:  {v.generalInfo}</p>
                      <br/>
                      <p>אופן השימוש:  {v.usage}</p>
                      <br/>
                      <p>{v.sku}</p>
                      </div>
                    </div>
                  );
                })}
                <button onClick={() => setOk(true)}>אישור</button>
              </div>
            )}
          </>
        )}
        {clientData?.error && <h2>  {clientData?.error}</h2>}
        {ok && (typeBuy === 'money' ? (
        <div className="ok-div"
        >
          <p>
            שם הקונה: {clientData["שם"]}
            <br />
            שם המועדון: {clientData["מועדון"]}
            <br />
            סכום הקניה: {sum}
            <br />
            מימוש מהכרטיס: {sum1}
            <br />
            תשלום בצורה אחרת: {other()}
          </p>
          {spiner === true ? 
        (
          <div className="divlod">
            <div className="loader"></div>
          </div>) :
          <>
           <button
            style={{ margin: "5px", backgroundColor: " rgb(210, 160, 124)" }}
            onClick={buy}
          >
            אישור
          </button>
          <button
            style={{ margin: "5px", backgroundColor: " rgb(210, 160, 124)" }}
            onClick={() => setOk(false)}
          >
            ביטול
          </button>
          </>  
        }
         
        </div>
      ) : (
        <div className="ok-div">
          <p>
            שם הקונה: {clientData["שם"]}
            <br />
            שם המועדון: {clientData["מועדון"]}
            <br />
            שוברים שנבחרו:
            {clientData?.vouchers?.map((v) => {
              return v.checked ? (
                <>
                  <br />
                  {v.title}
                </>
              ) : "";
            })}
          </p>
          {spiner === true ?
        ( <div className="divlod">
          <div className="loader"></div>
        </div>) :
        <>
          <button
            style={{ margin: "5px", backgroundColor: " rgb(210, 160, 124)" }}
            onClick={buy}
          >
            אישור
          </button>
          <button
            style={{ margin: "5px", backgroundColor: " rgb(210, 160, 124)" }}
            onClick={() => setOk(false)}
          >
            ביטול
          </button>
        </>
        }
        </div>
      ))}
      </div>
      <button onClick={() => window.location.reload()}>חזרה לדף הקודם</button>
 
    </div>
  );
}
