import { useState } from 'react';
import { useNavigate } from "react-router-dom";
import './Login.css';
import AutoHideAlert from '../alrt';
import url from '../url ';



function TwoStepsAuthenticat(props){
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
    const [isAlrt, setIsAlrt] = useState(false)
    const [message, setmessage] = useState('')
    const [Spiner, setSpiner] = useState(false);
    const [code, setCode] = useState('')

    const navigate = useNavigate()


    // קוד בשביל סמארטפון
    window.addEventListener('resize', () => {
      setIsMobile(window.innerWidth < 768);
    });
  
    const handleSubmit = async(event) => {
      event.preventDefault();
      setSpiner(true)
      try{
        const res = await fetch(`${url}/user/2fa`, {
          method: 'post',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({code})
        });
        const data = await res.json()
        if(data.message === 'ok'){
          navigate('/connection')
        }
        if(data.status === 'fail'){
          setmessage(data.error)
          setIsAlrt(true)
          setTimeout(() => {
            setmessage('')
            setIsAlrt(false)
          }, 5000);
          setSpiner(false)
          return;
        }
      }
      catch(e){
        console.log(e);
      }
      setSpiner(false)
    }

    return (
      <div className="App" >
        {isAlrt&& 
        
        <AutoHideAlert message={message} />
        }
        <h1>קהילות קארד</h1>
         <form onSubmit={handleSubmit} style={{ width:isMobile ? "70%" : "25%"}}>
          <h2>אימות דו שלבי</h2>
          <label>זיהינו כניסה ממחשב חדש, ולכן שלחנו לך קוד אימות. נא הכנס את הקוד.</label>
          <input type="number" name="code" id="code"
            placeholder="הכנס קוד"
            required
            value={code}
            onChange={(e) => setCode(e.target.value)}
          />
          {/* <RECAPTCHA /> */}
         {Spiner === true ?(
                <div className="divlod">
                  <div className="loader"></div>
                </div>) :
          <button type="submit">שלח</button>}
        </form>

      </div>
    );
  }
  
 
export default TwoStepsAuthenticat;