import { useEffect, useState } from "react";
import Information from "./information/information";
import "./panelnformation.css";
import url from "../../url ";

function Panelnformation(props) {
  const { permission } = props;
  const [data, setData] = useState({});
  const isMobile = window.innerWidth < 768;

  useEffect(() => {
    async function fetchData() {
      try {
        const res = await fetch(`${url}/${permission}/getBasicInfo`, {
          credentials: 'include',
          method: "post",
        });
        const info  = await res.json();
        setData(info);
      } catch (e) {
        console.log(e);
      }
    }

    fetchData();
  }, [permission]); // Or [] if effect doesn't need props or state

  return (
    <div className="panel" style={{position : isMobile ? "static" : ""}}>
      {Object.entries(data).map(([key, value]) => {
        return <div key={[key]} style={{display: 'inline'}}><Information titel={[key]} information={[value]} /></div>;
      })}
      {/* <Information titel="כרטיסים בתלוש בוט" information="500"/>
            <Information titel="כרטיסים בתלוש בוט" information="500"/>
            <Information titel="כרטיסים בתלוש בוט" information="500"/>
            <Information titel="כרטיסים בתלוש בוט" information="500"/>
            <Information titel="כרטיסים בתלוש בוט" information="500"/> */}
    </div>
  );
}
export default Panelnformation;
