import React, { useEffect, useState } from "react";

export function GropeLoading(props) {
  const [gropss, setgropss] = useState([]);
  const [show, setshow] = useState([]);
  const handleCheckboxChange1 = (index) => {
    let arr = [...gropss];
    for (let i in arr) {
      arr[i] = { ...gropss[i] };
    }
    for (let j in arr[index].arr) {
      arr[index].arr[j] = { ...gropss[index].arr[j] };
      arr[index].arr[j].checked = !gropss[index].checked;
    }
    arr[index].checked = !gropss[index].checked;
    // setIsChecked1(!isChecked1);
    setgropss(arr);
    props.setGropeCH(arr)
    props.setallCards(false)
  };

  const handleCheckboxChange2 = (indexi, indexj) => {
    let arr = [...gropss];
    for (let i in arr) {
      arr[i] = { ...gropss[i] };
      for (let j in arr[i].arr) {
        arr[i].arr[j] = { ...gropss[i].arr[j] };
      }
    }
    arr[indexi].arr[indexj].checked = !gropss[indexi].arr[indexj].checked;
    // setIsChecked1(!isChecked1);
    setgropss(arr);
    props.setGropeCH(arr)
    props.setallCards(false)
  };

  const hndelShow = (index) => {
    let arr = [...show];
    arr[index] = !show[index];
    setshow(arr);
  };

  useEffect(() => {
    setgropss(props.grops);
    let arrshow = [];
    for (let i in props.grops) {
      arrshow.push(false);
    }
    // setshow(arrshow);
  }, [props.grops]);

  useEffect(() => {
    let arr = [...gropss];
    for (let i in arr) {
      arr[i] = { ...gropss[i] };
      for (let j in arr[i].arr) {
        arr[i].arr[j] = { ...gropss[i].arr[j] };
        arr[i].arr[j].checked = props.allCards;
      }
      arr[i].checked = props.allCards;
    }

    setgropss(arr);
    props.setGropeCH(arr)
  }, [props.allCards1])

  // console.log(show);
  return (
    <div style={{ margin: "10px" }}>
      {gropss.map((grope, index) => {
        return (
          <div
            className="grope"
            style={{ marginTop: "5px", backgroundColor: "rgb(134, 82, 45)" }}
            key={grope.name}
          >
            <div style={{ display: "flex", backgroundColor: "rgb(210, 160, 124)" }}>
              <label style={{ display: "flex", flexWrap: "nowrap" }}>
                <input
                  style={{ width: "1cm" }}
                  type="checkbox"
                  checked={grope.checked}
                  onChange={() => handleCheckboxChange1(index)}
                />
                <span style={{ marginLeft: "5px" }}>{grope.name}</span>
              </label>

              <span
                style={{ marginRight: "auto", marginLeft: "3px" }}
                onClick={() => hndelShow(index)}
              >
                &gt;
              </span>
            </div>
            {show[index] &&
              grope.arr.map((card, ind) => {
                return (
                  <div key={card.id}>
                    <label style={{ display: "flex", flexWrap: "wrap" }}>
                      <input
                        style={{ width: "1cm" }}
                        type="checkbox"
                        checked={gropss[index].arr[ind].checked}
                        onChange={() => handleCheckboxChange2(index, ind)}
                      />
                      <span style={{ marginLeft: "5px" }}>
                        {props.clubsNames ? <>{card["שם פרטי עבור המועדון"]} {card["שם משפחה עבור המועדון"]} </> : <> {card["שם פרטי"]} {card["שם משפחה"]}</>}
                      </span>
                    </label>
                  </div>
                );
              })}
          </div>
        );
      })}
    </div>
  );
}
