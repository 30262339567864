import React, { useEffect } from "react";
import "./ChooseColmuns.css";

function ChooseColmuns (props) {
    const { listColmuns, colmuns, setColmuns, setChooseColmuns, table, chooseColmuns } = props;
    useEffect(() => {
        const dictColmuns = JSON.parse(localStorage.getItem('dictColmuns'));
        if (!dictColmuns) {
            localStorage.setItem('dictColmuns', JSON.stringify({}));
            setColmuns(listColmuns || []);
            return;
        }
        setColmuns(dictColmuns[table] || listColmuns || []);
    }, [listColmuns])
    return (
        <div>
            {chooseColmuns && 
                <div className="main-div">
                    {listColmuns.map((colmun, index) => {
                        return (
                            <div key={index}>
                                <input type="checkbox" checked={colmuns.includes(colmun)} id={colmun} name={colmun} value={colmun} onChange={(e) => {
                                    if (e.target.checked) {
                                        setColmuns([...colmuns, colmun])
                                        localStorage.setItem('dictColmuns', JSON.stringify({ ...JSON.parse(localStorage.getItem('dictColmuns')), [table]: [...colmuns, colmun] }))
                                    } else {
                                        setColmuns(colmuns.filter((column) => column !== colmun))
                                        localStorage.setItem('dictColmuns', JSON.stringify({ ...JSON.parse(localStorage.getItem('dictColmuns')), [table]: colmuns.filter((column) => column !== colmun) }))
                                    }
                                }} />
                                <label htmlFor={colmun}>{colmun}</label>
                            </div>
                        )
                    })}
                    <button onClick={() => setChooseColmuns(false)}>סיום</button>
                </div>
            }
        </div>
    )
}

export default ChooseColmuns;