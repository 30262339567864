import { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";

import "./CardLoading.css";
import { GropeLoading } from "./gropeLoading";
import { GropeStore } from "./gropeStore";
import AutoHideAlert from "../../../alrt";
import url from "../../../url ";

export function CardLoading() {
  const [storeRows, setstoreRows] = useState([]);
  const [cardRows, setcardRows] = useState([]);

  const [Spiner, setSpiner] = useState(false);
  const [allCards, setallCards] = useState(false);
  const [allStore, setallStore] = useState(false);
  const [allStore1, setallStore1] = useState(0);// בשביל שרק מתי שזה משתנה יופעל האפקט בחניות
  const [allCards1, setallCards1] = useState(0);

  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [gropeCH, setGropeCH] = useState([]);
  const [gropeSyores, setGropeStores] = useState([]);

  const [serchCard, setSerchCard] = useState("");
  const [serchStore, setSerchStore] = useState("");

  const [arrserchCard, setarrserchCard] = useState([]);
  const [arrserchStore, setarrserchStore] = useState([]);

  const [sum, setSum] = useState('');
  const [date, setDate] = useState("");
  const [noteForManager, setNoteForManager] = useState("");
  const [select, setSelect] = useState("");
  const [select1, setSelect1] = useState("");
  const [arridc, setarridc] = useState([]);
  const [arrids, setarrids] = useState([]);
  const [isAlrt, setIsAlrt] = useState(false)
  const [message, setmessage] = useState('')
  const [h, setH] = useState('')
  const [ok, setOk] = useState(false);
  const [typeLoad, setTypeLoad] = useState('money');
  const [voucherId, setVoucherId] = useState();
  const [voucherName, setVoucherName] = useState();
  const [vouchersTransactionRows, setVouchersTransactionRows] = useState([]);
  const [vouchersStores, setVouchersStores] = useState([]);
  const [categorys, setCategorys] = useState([]);
  const [clubsNames, setClubsNames] = useState(false);

  // קוד בשביל סמארטפון
  window.addEventListener("resize", () => {
    setIsMobile(window.innerWidth < 768);
  });


  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const isAdmin = searchParams.get("is-admin");

  const idFromUrl = useParams().id;

  const id = isAdmin ? process.env.REACT_APP_ADMIN_CLUB_ID : idFromUrl;

  useEffect(() => {
    // console.log(id);
    const req = async () => {
      setSpiner(true);
      const urlRowsCards = `${url}/${isAdmin ? "admin" : `club/${id}`}/get-table-info/cards/rows`;
      const urlRowsStore = `${url}/club/${id}/get-table-info/store_club_agreements_for_sid/rows`;
      const urlRowsVouchersTransactions = `${url}/club/${id}/get-table-info/vouchers_transactions/rows`;
      const getClubPrefrences = `${url}/club/${id}/get-club-preference`;

      
      //קבלת כרטיסים
      fetch(urlRowsCards, {
        method: "POST",
        credentials: 'include',
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          filter: {'1active': [{ type: '=', value: '1', relation: 'and' }]},
          sort: {},
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          const {rows} = data;
          setcardRows(rows);
        })
        .catch((error) => console.error("Error:", error))
        .then(() => setSpiner(false));

      //קבלת חנוית
      fetch(urlRowsStore, {
        method: "POST",
        credentials: 'include',
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          filter: {},
          sort: {},
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          const {rows} = data;
          console.log(rows);
          setstoreRows(rows);
        })
        .catch((error) => console.error("Error:", error))
        .then(() => setSpiner(false));

      //קבלת שוברים
      fetch(urlRowsVouchersTransactions, {
        method: "POST",
        credentials: 'include',
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          filter: {'1active': [{ type: '=', value: 'מאושר', relation: 'and' }]}, // '1active': '1
          sort: {},
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          const {rows} = data;
          setVouchersTransactionRows(rows);
          const storeList = rows.reduce((acc, row) => {
            if (!acc.includes(row["שם החנות"])) {
              acc.push(row["שם החנות"]);
            }
            return acc;
          }, []);
          setVouchersStores(storeList);
        })
        .catch((error) => console.error("Error:", error))

      //קבלת פרטי המועדון
      fetch(getClubPrefrences, {
        method: "POST",
        credentials: 'include',
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          column: 'show_club_names',
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
          setClubsNames(data?.preference);
        })
        .catch((error) => console.error("Error:", error))
      
      setSpiner(true);
    };
      req();
  }, [id]);
  useEffect(() => {
    let gropse = sub(cardRows, isAdmin);
    let gropse1 = pp(cardRows, gropse, isAdmin);

    for (let grope of gropse1) {
      grope.checked = false;
      for (let cerd of grope.arr) {
        cerd.checked = false;
      }
    }
    setGropeCH(gropse1);
    // console.log(dataRoesSrore[0]);

    let categorie = StoreCategories(storeRows);
    let categories = stores(categorie, storeRows);
    setGropeStores(categories);
  }, [storeRows, cardRows, isAdmin]);

  const hndelallCards = () => {
    setallCards(!allCards);
    setallCards1((p)=>p+1)
  };
  const hndelallStore = () => {
    setallStore(!allStore);
    setallStore1((p)=>p+1)
  };
  const hndelSerchCard = (e) => {
    setSerchCard(e.target.value);
    let arr = [];
    if (e.target.value) {
      arr = searchInObject(gropeCH, e.target.value);
    }
    setarrserchCard(arr);
  };
  const hndelSerchStore = (e) => {
    setSerchStore(e.target.value);
    let arr = [];
    if (e.target.value) {
      arr = serchInStore(gropeSyores, e.target.value, select);
    }
    setarrserchStore(arr);
  };

  const serchCardCH = (i, j) => {
    let arr = [...gropeCH];
    for (let i in arr) {
      arr[i] = { ...gropeCH[i] };
      for (let j in arr[i].arr) {
        arr[i].arr[j] = { ...gropeCH[i].arr[j] };
      }
    }
    arr[i].arr[j].checked = !gropeCH[i].arr[j].checked;
    setGropeCH(arr);
  };

  const serchStoreCH = (indexi, indexj) => {
    let arr = [...gropeSyores];
    arr[indexi][1][indexj].checked = !gropeSyores[indexi][1][indexj].checked;
    setGropeStores(arr);
  };

  const alert = (msg) => {
    setmessage(msg);
    setIsAlrt(true);
    setTimeout(() => {
      setmessage("");
      setIsAlrt(false);
    }, 5000);
  };

  const hndelLOd = () => {
    let arr = [];
    for (let i in gropeCH) {
      for (let j in gropeCH[i].arr) {
        if (gropeCH[i].arr[j].checked) {
          arr.push(gropeCH[i].arr[j].id);
        }
      }
    }
    let arrcard = Array.from(new Set(arr));
    setarridc(arrcard);
    if (typeLoad === 'money') {
      let arridstore = [];
      let categories = [];
      for (let i in gropeSyores) {
        if (gropeSyores[i][2]) {
          categories.push(gropeSyores[i][0]);
          continue;
        }
        for (let j in gropeSyores[i][1]) {
          if (gropeSyores[i][1][j].checked) {
            arridstore.push(gropeSyores[i][1][j].id);
          }
        }
      }
      setCategorys(categories);
      // console.log(arridstore);
      setarrids(arridstore);
      if (sum === '') {
        alert("הזן סכום לטעינה");
        return
      }
      // console.log(arrids);
      if (select === '') {
        alert("בחר צורת טעינה");
        return
      }
      if (arridstore.length === 0 && !allStore && categorys.length === 0)  {
        alert("לא נבחרו חנויות לטעינה");
        return
      }
      if (arrcard.length === 0 )  {
        alert("לא נבחרו כרטיסים לטעינה");
        return
      }
    } else {
      if (arrcard.length === 0 )  {
        alert("לא נבחרו כרטיסים לטעינה");
        return
      }
      if (voucherId === undefined) {
        alert("לא נבחר שובר לטעינה");
        return
      }
    }
    // console.log(parseInt(sum) * arrcard.length);
    setOk(true);
  };
  const hndelok = async () => {
    setSpiner(true)
    if (typeLoad === 'money') {
      let obj = {
        // all_stores :true,
        stores: arrids,
        categorys,
        cards: arridc,
        "צורת טעינה": select,
        סכום: sum,
        "בתוקף עד": date,
        'הערות': h,
        "סוג טעינה" : select1
      };
      if (allStore) {
        delete obj.stores;
        delete obj.categorys;
        obj.all_stores = true;
      }
      try {
        const urlLod = `${url}/club/${id}/add-raw/add/cards_load`;
        const res = await fetch(urlLod, {
          method: "POST",
          credentials: 'include',
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(obj),
        });
        const dataa = await res.json();
        setSpiner(false)
        if (dataa.status === "success") {
          setmessage("  הטעינה הושלמה בהצלחה")
          setIsAlrt(true)
          setTimeout(() => {
            setmessage("")
            setIsAlrt(false)
            window.history.back();
            return;
          }, 3000);

        } else {
          // alert("אירעה שגיאה נסה שוב" + " " + dataa?.error);
          setmessage("אירעה שגיאה נסה שוב " + dataa?.error);
          setIsAlrt(true)
          setTimeout(() => {
            setmessage("")
            setIsAlrt(false)
          }, 5000);

        }
      } catch (e) {
        // alert("אירעה שגיאה נסה שוב" + e);
        setmessage("אירעה שגיאה נסה שוב" + e);
        setIsAlrt(true)
        setTimeout(() => {
          setmessage("")
          setIsAlrt(false)
        }, 5000);
        return
      }
    } else {
      const urlLod = `${url}/club/${id}/add-raw/add/vouchers_units_cards`;
      const res = await fetch(urlLod, {
        method: "POST",
        credentials: 'include',
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          card_id: arridc,
          voucher_id: voucherId,
          "בתוקף עד": date,
          "הערות": h,
          "סוג טעינה" : select1,
          "הערות למנהל המועדון": noteForManager
        }),
      });
      const dataa = await res.json();
      setSpiner(false)
      if (dataa.status === "success") {
        setmessage("  הטעינה הושלמה בהצלחה")
        setIsAlrt(true)
        setTimeout(() => {
          setmessage("")
          setIsAlrt(false)
          window.history.back();
          return;
        }, 3000);
      } else {
        setmessage("אירעה שגיאה נסה שוב " + dataa?.error);
        setIsAlrt(true)
        setTimeout(() => {
          setmessage("")
          setIsAlrt(false)
        }, 5000);
      }
    }
    setOk(false);
  };
  const hndelok1 = async () => {
    setOk(false);
  };

  return (
    <>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div style={{ width: "50%", height: '50px', display: "inline-block" }} >
          <button style={{}} onClick={() => window.history.back()}>X</button>
        </div>
        <div style={{ textAlign: "left", width: "50%", display: "inline-block" }} >
          <img src="/zz.png" alt="סמל" width="150" height="60" />
        </div>
      </div>      {isAlrt &&

        <AutoHideAlert message={message} />
      }
      <div className="root">
        <div className="root1" style={{ width: isMobile ? "70%" : "25%" }}>
          <h1>טעינה לכרטיסים</h1>
        <div>
          <label htmlFor="cc">סוג טעינה</label>
          <br />
          <button 
          onClick={() => setTypeLoad('money')}
          style={{ 
            backgroundColor: typeLoad === 'money' ? "" : "white",
            color: typeLoad === 'money' ? "white" : "black",
            border: "1px solid black"
          }}>כסף</button>
          <button
          onClick={() => setTypeLoad('voucher')}
          style={{ 
            backgroundColor: typeLoad === 'voucher' ? "" : "white", 
            color: typeLoad === 'voucher' ? "white" : "black",
            border: "1px solid black"
          }}>שוברים</button>
          </div>
          <br />
        
        {typeLoad === 'money' ? (
          <div>
          <div className="sum">
            <label htmlFor="sum" className="label">
              הזן סכום
            </label>
            <input
              type="number"
              inputMode="numeric"
              // -moz-appearance: textfield;
              moz-appearance = "textfield"
              textfield = "-moz-ppearance" 
              id="sum"
              value={sum}
              onChange={(e) => setSum(e.target.value)}
              // style={{
              //   MozAppearance: 'textfield',
              //   WebkitAppearance: 'none',
              //   appearance: 'none',
              //   // width: '100%', // קפיץ שורה
              //   // border: '1px solid #ccc',
              //   // padding: '8px',
              // }}
            />
          </div>
          {/* <h6>סך הכל לכל המועדונים 12345 ש"ח</h6> */}
          <div>
            <label htmlFor="date" className="label">
              בתוקף עד
            </label>
            <input
              type="date"
              id="date"
              value={date}
              onChange={(e) => setDate(e.target.value)}
            />
          </div>
          <br />
          <div>
            <label htmlFor="cc">צורת טעינה</label>
            <br />
            <button 
            onClick={() => setSelect("התחייבות")} 
            style={{ 
              backgroundColor: select === "התחייבות" ? "" : "white",
              color: select === "התחייבות" ? "white" : "black",
              border: "1px solid black"
            }}>התחייבות</button>
            <button 
            onClick={() => setSelect("טעינה מהארנק")} 
            style={{ 
              backgroundColor: select === "טעינה מהארנק" ? "" : "white", 
              color: select === "טעינה מהארנק" ? "white" : "black",
              border: "1px solid black"
            }}>טעינה מהארנק</button>
          </div>
          <div>
            <label htmlFor="cc">סוג טעינה</label>
            <br />
            <button
              onClick={() => setSelect1("תמיכה")}
              style={{
                backgroundColor: select1 === "תמיכה" ? "" : "white",
                color: select1 === "תמיכה" ? "white" : "black",
                border: "1px solid black"
              }} >תמיכה</button>
            <button
              onClick={() => setSelect1('שולם ע"י הבחור')}
              style={{
                backgroundColor: select1 === 'שולם ע"י הבחור' ? "" : "white",
                color: select1 === 'שולם ע"י הבחור' ? "white" : "black",
                border: "1px solid black"
              }} >שולם ע"י הבחור</button>
          </div>
          <p>בחר לאלו קטגרוית/חנוית יטען הסכום</p>
          <br />
          <div className="possible">
            <label htmlFor="possible">אפשר לכלל העסקים</label>
            <input
              type="checkbox"
              id="possible"
              checked={allStore}
              onChange={hndelallStore}
            />
          </div>

          <div className="serch">
            <label htmlFor="serch">חיפוש חנות</label>
            <input
              type="text"
              id="serch"
              value={serchStore}
              onChange={hndelSerchStore}
            />
            <div>
              {arrserchStore.map((obj) => {
                return (
                  <div key={obj.name}>
                    <label style={{ display: "flex", flexWrap: "wrap" }}>
                      <input
                        style={{ width: "1cm" }}
                        type="checkbox"
                        checked={gropeSyores[obj.i][1][obj.j].checked}
                        onChange={() =>{ serchStoreCH(obj.i, obj.j); setallStore(false)}}
                      />
                      <span style={{ marginLeft: "5px" }}>{obj.name}</span>
                    </label>
                  </div>
                );
              })}
            </div>
          </div>
          <div>
            {/* <span style={{ fontSize: "10px" }}>
            שים לב לא ניתן לבחור חניות שצורת התשלום שלהם שונה
          </span> */}
            <GropeStore grops={gropeSyores} setGrops={setGropeStores} allStore={allStore} pay={select} setallStore={setallStore} allStore1={allStore1}/>
          </div>
          </div>
          ):(
          <>
            <br />
            <select name="selectVoecerStore"
              id="selectVoecerStore"
              value={voucherName}
              onChange={(e) => setVoucherName(e.target.value)}
              className="select"
            >
              <option value="">בחר חנות</option>
              {vouchersStores.map((store) => (
                <option key={store} value={store}>{store}</option>
              ))}
            </select>
            <br />
            {voucherName && (
              <div>
                {vouchersTransactionRows.map((voucher) => {
                  if (voucher["שם החנות"] && voucher["שם החנות"] === voucherName) {
                    return (
                      <div key={voucher['id']}>
                        <label htmlFor={voucher["שם השובר"]}>
                          שם השובר {voucher["שם השובר"]}
                          <br />
                          תוקף השובר {voucher['תוקף השוברים']}
                          <br />
                          כמות שוברים פנויים {voucher['פנויים']}
                        </label>
                        <input
                          type="radio"
                          name="voucher"
                          id={voucher["שם השובר"]}
                          value={voucher["שם השובר"]}
                          onChange={() => setVoucherId(voucher["id"])}
                        />
                      </div>
                    );
                  }
                  return null;
                })
                }
              </div>
            )}
            <br />
            <label htmlFor="date" className="label">
              בתוקף עד
            </label>
            <input
              type="date"
              id="date"
              value={date}
              onChange={(e) => setDate(e.target.value)}
            />
            <br />
            <br />
            <label htmlFor="date" className="label">
              הערות למנהל המועדון
            </label>
            <input
              type="text"
              id="date"
              value={noteForManager}
              onChange={(e) => setNoteForManager(e.target.value)}
            />
            <div>
              <label htmlFor="h">הערות</label>
              <input type="text" id="h" value={h} onChange={(e) => setH(e.target.value)} />
            </div>
            <div>
            <label htmlFor="cc">סוג טעינה</label>
            <br />
            <button
              onClick={() => setSelect1("תמיכה")}
              style={{
                backgroundColor: select1 === "תמיכה" ? "" : "white",
                color: select1 === "תמיכה" ? "white" : "black",
                border: "1px solid black"
              }} >תמיכה</button>
            <button
              onClick={() => setSelect1('שולם ע"י הבחור')}
              style={{
                backgroundColor: select1 === 'שולם ע"י הבחור' ? "" : "white",
                color: select1 === 'שולם ע"י הבחור' ? "white" : "black",
                border: "1px solid black"
              }} >שולם ע"י הבחור</button>
          </div>
          </>
          )}
          
          <br />
          <br />
          
          <p>בחר לאילו קבוצות/כרטיסים להטעין</p>

          <div className="possible">
            <label htmlFor="possible" className="lpossible">
              טען לכולם
            </label>
            <input
              type="checkbox"
              id="possible"
              className="ipossible"
              checked={allCards}
              onChange={hndelallCards}
            />
          </div>
          <br />
          <div className="serch">
            <label htmlFor="serch">חיפוש כרטיס</label>
            <input
              type="text"
              id="serch"
              value={serchCard}
              onChange={hndelSerchCard}
            />
            <div>
              {arrserchCard.map((obj) => {
                return (
                  <div key={obj.card.id}>
                    <label style={{ display: "flex", flexWrap: "wrap" }}>
                      <input
                        style={{ width: "1cm" }}
                        type="checkbox"
                        checked={gropeCH[obj.i].arr[obj.j].checked}
                        onChange={() => {serchCardCH(obj.i, obj.j); setallCards(false)}}
                      />
                      <span style={{ marginLeft: "5px" }}>
                        {clubsNames ? <>{obj.card["שם פרטי עבור המועדון"]} {obj.card["שם משפחה עבור המועדון"]}</> : <>{obj.card["שם פרטי"]} {obj.card["שם משפחה"]}</>} , קוד הכרטיס {obj.card["קוד הכרטיס"].slice(-7)}, פלאפון  {obj.card["פלאפון"]}
                      </span>
                    </label>
                  </div>
                );
              })}
            </div>
          </div>

          <GropeLoading
            grops={gropeCH}
            allCards={allCards}
            setGropeCH={setGropeCH}
            setallCards={setallCards}
            allCards1={allCards1}
            clubsNames={clubsNames}
          />{typeLoad === "money" && (
            <div>
              <label htmlFor="h">הערות</label>
              <input type="text" id="h" value={h} onChange={(e) => setH(e.target.value)} />
            </div>
          )}
          <button
            style={{ backgroundColor: "rgb(210, 160, 124)", margin: "2%" }}
            onClick={hndelLOd}
          >
            בצע טעינה
          </button>

          {ok &&(
            <div
              style={{
                border: "1px black solid",
                position: "fixed",
                top: "50%",
                left: "50%", // שינוי כדי להצמיד לאמצע העמוד
                transform: "translate(-50%, -50%)",
                zIndex: "1",
                backgroundColor: "white",
                borderRadius: "20px",
              }}
            >
              <h4>אנא אשר שוב </h4>
              {
              typeLoad === 'money' ? (
              <div>
                <p>סכום הטענה לכל כרטיס: {sum}</p>
                <p>כמות כרטיסים: {arridc.length}</p>
                <p>סך הכל: {parseInt(sum) * arridc.length}</p>
              </div>
              ):
              (
                <div>
                  <p>כמות כרטיסים: {arridc.length}</p>
                  <p>שובר: {voucherName}</p>
                </div>
              )}
             
              {Spiner === true ?(
                <div className="divlod">
                  <div className="loader"></div>
                </div>) :<>  
                   <button
                   style={{ margin: "5px", backgroundColor: " rgb(210, 160, 124)" }}
                   onClick={hndelok}
                 >
                   אישור
                 </button>
                 <button
                   style={{ margin: "5px", backgroundColor: " rgb(210, 160, 124)" }}
                   onClick={hndelok1}
                 >
                   ביטול
                 </button>
                 </>
              }
            </div>
          )}
        </div>
      </div>
    </>
  );
}

function sub(arr, isAdmin) {
  let result = [];
  let filter = isAdmin ? 'שם המועדון' : 'קבוצות';
  for (let i = 0; i < arr.length || 0; i++) {
    if (arr[i][filter] === null) {
      // break;
      result.push({ name: 'ללא קבוצה', arr: [] })
    } else {
      let grops = splitStringByComma(arr[i][filter]);
      result.push(...grops);
    }
  }
  return removeDuplicateObjects(result);
}
// חותך את המחרוזות של הקבוצות למערך
function splitStringByComma(inputString) {
  let arr = [];
  if (inputString) {
    inputString.split(",").forEach((item) => {
      item.trim();
      arr.push({ name: item, arr: [] });
    });
  }
  else {
    arr.push({ name: 'ללא קבוצה', arr: [] })
  }
  return arr;
}

// בודק שאין כפילות של קבוצות
function removeDuplicateObjects(arr) {
  let uniqueArray = [];
  let keysSet = new Set();

  arr.forEach((obj) => {
    // בדיקה האם יש לנו כבר אובייקט עם אותו מפתח
    if (!keysSet.has(obj.name)) {
      uniqueArray.push(obj);
      keysSet.add(obj.name);
    }
  });

  return uniqueArray;
}
//הוספת כרטיס לקבוצה
function findObjectByName(name, array, card) {
  for (let i = 0; i < array.length; i++) {
    if (array[i].name === name) {
      array[i].arr.push(card);
      return;
    }
  }
  //   console.log(array);
}
function pp(cards, gc, isAdmin) {
  const filter = isAdmin ? 'שם המועדון' : 'קבוצות';
  for (let i = 0; i < cards.length; i++) {
    let arr = [];
    if (cards[i][filter] != null && cards[i][filter] !== undefined) {
      cards[i][filter].split(",").forEach((item) => {
        item.trim();
        arr.push(item);
      });
    }
    else {
      const targetObject = gc.find(item => item.name === "ללא קבוצה");
      if (targetObject) {
        targetObject.arr.push(cards[i])
      }

    }
    // console.log(arr);
    for (let card of arr) {
      //   console.log(card);
      findObjectByName(card, gc, cards[i]);
    }
  }
  
  return gc;
}

function searchInObject(array, searchTerm) {
  let arrs = [];
  //עובר על המערך של הקבוצות
  for (let i = 0; i < array.length; i++) {
    //עובר על המערך של הכרטיסים בתוך הקבוצות
    for (let j = 0; j < array[i].arr.length; j++) {
      // עובר על כל מאפיין באובייקט
      for (const key in array[i].arr[j]) {
        // בודק אם הערך של המאפיין תואם לחיפוש
        // console.log(array[i].arr[j].hasOwnProperty(key));
        // console.log(typeof array[i].arr[j][key] === 'string');
        if (
          typeof array[i].arr[j][key] === "string" &&
          array[i].arr[j][key].includes(searchTerm)
        ) {
          // אם יש התאמה, מחזיר את האובייקט
          let fleg = false;
          if (arrs.length > 0) {
            for (let ind in arrs) {
              if (arrs[ind].card.id === array[i].arr[j].id) {
                fleg = true;
              }
            }
          }
          !fleg && arrs.push({ i: i, j: j, card: array[i].arr[j] });
          break;
        }
        // arrs.push(searchTerm)
      }
    }
  }
  // עובר על כל אובייקט במערך
  // אם לא נמצאה התאמה, מחזיר null
  return arrs;
}
//חיפוש חנות
function serchInStore(arr, str, select) {
  let arrs = [];
  for (let i in arr) {
    // console.log(i);
    for (let j in arr[i][1]) {
      // console.log(j.name );

      if (
        typeof arr[i][1][j].name === "string" &&
        arr[i][1][j].name.includes(str)
      ) {
        // console.log(arr[i][1][j]);
        // שהחיפוש יציג רק חניות מלפי הצורת תשלום 
        if (arr[i][1][j].payment.includes(select)) { // נמחק לבקשת הלקוח
          arrs.push({ ...arr[i][1][j], i: i, j: j });
        }
      }
    }
  }
  return arrs;
}

// יצירת קטגוריות לחנוית
function StoreCategories(arr) {
  let categories = [["ללא קטגוריה", [], false]];

  for (let i in arr) {
    if (arr[i]["קטגוריית החנות"]) {
      categories.push([arr[i]["קטגוריית החנות"], [], false]);
    }
  }
  let uniqueArrays = new Set(categories.map(JSON.stringify));
  let resultArray = Array.from(uniqueArrays).map(JSON.parse);
  return resultArray;
}
// הכנסה לקטגוריות
function stores(categories, stores) {
  for (let i in stores) {
    if (stores[i]["קטגוריית החנות"] == null) {

      categories[0][1].push({
        name: stores[i]["שם החנות"],
        checked: false,
        id: stores[i].store_id,
        payment: pay(stores[i])
      });
      // console.log(stores[i]);
    } else {
      let index = categories.findIndex(
        (subArray) => subArray[0] === stores[i]["קטגוריית החנות"]
      );
      // console.log(index);
      if (index !== -1)
        categories[index][1].push({
          name: stores[i]["שם החנות"],
          checked: false,
          id: stores[i].store_id,
          payment: pay(stores[i])
        });
    }
  }
  return categories;
}

const pay = (obj) => {
  let add = [];
  if (obj["תשלום מהארנק"] === 'true') {
    add.push('טעינה מהארנק')
  }
  if (obj["תשלום בהתחייבות"] === 'true') {
    add.push('התחייבות')
  }
  if (add.length === 0) {
    return ''
  } else {
    return String(`[${add.join(', ')}]`)
  }

}