import React, { useState, useEffect } from "react";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";

const AutoHideAlert = ({ message }) => {
    const [open, setOpen] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => {
            setOpen(false);
        }, 10000);

        return () => {
            clearTimeout(timer);
        };
    }, []);

    return (
        
        <div style={{  position: 'fixed', border: '1px black solid', top: "15%", left: "50%", transform: "translate(-50%, -50%)", backgroundColor: "white", borderRadius: '5px' }} >
        
            <Snackbar
                open={open}
                autoHideDuration={null}
                onClose={() => setOpen(false)}
            >
                <Alert severity="info" onClose={() => setOpen(false)}>
                    {" "+message+" "}
                </Alert>
            </Snackbar>
        </div>
    );
};

export default AutoHideAlert;
