import Table from "./table";
import { useState, useEffect } from "react";
import "./pageTable.css";
import IndexPage from "./indexPage/indexPage";
import { Link, useNavigate } from "react-router-dom";
import FilterData from "./filterData/filterData";
import url from "../../url ";
import { tableAccess, viewObjects, ChooseTable } from "./ChooseTable/ChooseTable";
import Search from "./Search/Search";
import PopUpForPay from "./PopUpForPay/PopUpForPay";
import ChooseColmuns from "./ChooseColmuns/ChooseColmuns";
import { AddCards } from "./AddCards/AddCards";
import { RemoveCards } from "./RemoveCards/RemoveCards";



function PageTable(props) {
  const { role, id } = props;
  const [rows, setRows] = useState([]);
  const [sumOfRows, setSumOfRows] = useState(0);
  const [sums , setSums] = useState(0);
  const [dataColmuns, setdataColmuns] = useState([]);
  const [table] = useState(props.table);
  const [page, setPage] = useState(0);
  const [spiner, setSpiner] = useState(false);
  const [sort, setSort] = useState("");
  const [columnd, setcolumnd] = useState("");
  const isEdit = tableAccess[role].edit.includes(props.table);
  const isAdd = tableAccess[role].add.includes(props.table);
  const [active, setActive] = useState(true);
  const [tableForPay, setTableForPay] = useState('');
  const [bolunData, setBolunData] = useState(false)
  const [hbNameTable, sethbNameTable] = useState('')
  const [isMobile] = useState(window.innerWidth < 768);
  const nav = useNavigate();  
  const [lod, setLod] = useState(false);
  const [idStorePay, setidStorePay] = useState('');
  const [searchAll, setSearchAll] = useState(undefined);
  const [dateFilter, setDateFilter] = useState(intinlDateFilter());
  const [colmuns, setColmuns] = useState([]);
  const [chooseColmuns, setChooseColmuns] = useState(false);
  const [popupForAddCards, setPopupForAddCards] = useState(0);
  const [popupForRemoveCards, setPopupForRemoveCards] = useState(0);


  useEffect(() => {
    fetch(`${url}/${role + (id? `/${id}` : '')}/get-table-info/${table}/colmuns`,
     {
      credentials: 'include',
      method: "post",
    }
    )
    .then((response) => response.json())
    .then((data) => {
      setdataColmuns(data)
    })
    .catch((error) => console.error("Error:", error));
    sethbNameTable(viewObjects[table])
  }, [id, role, table]);

  const storePayLod = async(id) =>{
    setidStorePay(id)
    setLod(true)
    setTableForPay('club_store_payments')
  }

  function intinlDateFilter () {
    const today = new Date();
    const oneMonthAgo = new Date();
    oneMonthAgo.setDate(1);

    const formatDate = (date) => {
        return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
      };
    return [{ type: '>=', value: formatDate(oneMonthAgo), relation: 'and' }, { type: '<=', value: `${formatDate(today)} 23:59:59`, relation: "and" }];
  }

  //מיון נקרא מהטיבל

  const hndelSort = (column) => {
    if (sort === "asc") {
      setSort("desc");
      setcolumnd(column);
    } else {
      setSort("asc");
      setcolumnd(column);
    }
  };
  // useEffect(() => {
  //   setSpiner(true);
  //   req(table, page)
  // }, [columnd, sort, num])
  function createFilter() {
    let filter = {};
    if (searchAll) {
      filter['all'] = searchAll;
    }
    if (dataColmuns.filterByActive) {
      let filterType = active ? '=' : '!=';
      let relation = active ? 'or' : 'and';
      filter['1active'] = dataColmuns.filterByActive.map((item) => {return { type: filterType, value: item, relation: relation }});
    }
    if (dataColmuns.filterByDate && dateFilter) {
      filter['1date'] = dateFilter;
    }
    return filter;
  }

  useEffect(() => {
    const req = (table, paget) => {
    if (dataColmuns.length === 0) return;
    setSpiner(true);
    const filter = createFilter();
    const urlRows = `${url}/${role + (id? `/${id}` : '')}/get-table-info/${table}/rows`;
    fetch(urlRows, {
      method: "POST",
      credentials: 'include',
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        filter: filter,
        sort: { column: columnd, order: sort },
        pos: paget,
        limit: 30,
        getSum: true,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        let { rows, sumOfRows, sums } = data;
        setRows(rows);
        setSumOfRows(sumOfRows);
        setSums(sums);
      })
      .catch((error) => console.error("Error:", error))
      .then(() => setSpiner(false));
    
    setSpiner(true);
  };
    req(table, page);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sort, page, searchAll, table, role, id, columnd, active, dataColmuns, dateFilter]);
  //פומקציית בקשה API
  

  const hndelPage = async (i) => {
    if (i < 0 || i > Math.floor(sumOfRows / 30)) {
      return;
    }
    setPage(i);
  };

  //פונקציה בשביל אקסל
  const reqExsl = async () => {
    const urlRows = `${url}/${role + (id? `/${id}` : '')}/export-excel/${table}`;
    try {
      // const urlRows = `${url}/${role + (id? `/${id}` : '')}/get-table-info/${table}/rows`;
      const filter = createFilter();
      const res = await fetch(urlRows, {
        method: "POST",
        credentials: 'include',
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          filter: filter,
          sort: { column: columnd, order: sort },
          pos: page,
          colmuns,
        }),
      });
      if (!res.ok) {
        throw new Error(`HTTP Error! Status: ${res.status}`);
      }

      const blob = await res.blob();
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `${table}`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (e) {
      console.error(e);
    }
  };
  const [unloading, setUnloading] = useState({ st: false, obj: {} })
  const Unloading = (obj) => {
    setUnloading({ st: true, obj: obj })
  }
  const UnloadingReq = async () => {
    try {
      const link = `${url}/${role}/${id}/canselLoad`;
      const res = await fetch(link, {
        method: "POST",
        credentials: 'include',
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id: unloading.obj.id,
          table,
        }),
      });
      await res.json();
      if (res.ok) {
        alert("הצליח");
        window.location.reload();
        return;
      }
    } catch (e) {
      console.error(e);
    }
    alert('אירעה שגיאה נסה שוב')
    setUnloading({ st: false, obj: {} })
  }

  const clearLogs = async () => {
    try {
      const link = `${url}/${role}/clear-logs`;
      const res = await fetch(link, {
        method: "POST",
        credentials: 'include',
        headers: {
          "Content-Type": "application/json",
        },
      });
      await res.json();
      if (res.ok) {
        alert("הצליח");
        window.location.reload();
        return;
      }
    } catch (e) {
      console.error(e);
    }
    alert('אירעה שגיאה נסה שוב')
    setTimeout(() => {
      window.location.reload();
    }, 2000);
  }
  useEffect(() => {
    setBolunData(false)
  } , [dateFilter])
  
  const openPayForClubToKeilotkard = () => {
    setLod(true)
    setTableForPay('club_load_history')
  }

  useEffect(() => {
    setPage(0);
  }
  , [searchAll, columnd, active, dateFilter])

    async function deleteReportRow (id) {try {
      const link = `${url}/${role}/delete-report-raw`;
      const res = await fetch(link, {
        method: "POST",
        credentials: 'include',
        body: JSON.stringify({id}),
        headers: {
          "Content-Type": "application/json",
        },
      });
      await res.json();
      if (res.ok) {
        alert("הצליח");
        window.location.reload();
        return;
      }
    } catch (e) {
      console.error(e);
    }
    alert('אירעה שגיאה נסה שוב')
    setTimeout(() => {
      window.location.reload();
    }, 2000); 
  }

  return (
    <div>
      <PopUpForPay id={id} idStorePay={idStorePay} lod={lod} setLod={setLod} table={tableForPay} role={role} />
      <ChooseTable table={table} role={role} isMobile={isMobile} />
      {/* <br /><br /><br /><br /><br /><br /> */}
      {role === 'storeManager' && table === 'reports_for_buys_by_date' && (
        <div>
          <button onClick={() => nav(`/edit?table=stores&id=${id}&permission=${role}&permissionID=${id}`)} style={{ backgroundColor: 'white', color: 'black' }}>ערוך פירטי חנות</button>
        </div>
      )}
      {
        table === 'request_logs' &&
        (
          <div>
            <button onClick={clearLogs} style={{ backgroundColor: 'white', color: 'black' }}>ניקוי הטבלה</button>
          </div>
        )
      }
      <div className="cvc">
        <div className="addp">
          {isAdd && (
              <Link
                to={{
                  pathname: "/add",
                  search: `?table=${table}&permission=${role + (id? `&permissionID=${id}` : '')}`,
                }}
              >
                <button className="btn-add" >רשומה חדשה</button>
              </Link>
          )}
          {role==='club' && (
          <div>
          <button className="btn-add" onClick={() => nav(`/cardLoading/${id}`)}>הטענה לכרטיסים  </button>
          <button onClick={openPayForClubToKeilotkard} className="btn-add">הטענה לארנק </button>
          </div>
          )}
          {role === 'storeManager' && (
            <button className="btn-add" onClick={() => {
              setLod(true);
              setTableForPay('store_pay_to_keilotkard');
            }}>תשלום עמלה לקהילות קארד</button>
          )}
          {role === 'admin' && (
            <button className="btn-add" onClick={() => {nav('/cardLoading?is-admin=true')}}>הטענה לכרטיסים</button>
          )}
          <div style={{ display: 'inline-block', margin: 'auto', textAlign: 'center' }}>
          
    </div>
          <button
            onClick={reqExsl}
            style={{
              borderRadius: "50%",
              height: "min-content",
              backgroundColor: "white",
              padding: "5px",
              border: "1px black solid ",
              margin: "5px",
            }}
          >
            <img src="/mm.png" alt="I" width={"20px"} height={"20px"} />
          </button>
        </div>

        <Search searchAll={searchAll} setSearchAll={setSearchAll} />
      </div>
      {dataColmuns.filterByActive && (<div>
        <button 
          onClick={() => setActive(true)}
          style={{
            backgroundColor: active ? "#5600ff" : "white",
            color: active ? "white" : "black",
            border: "1px solid black",
          }}>פעיל</button>
        <button 
          onClick={() => setActive(false)}
          style={{
            backgroundColor:
              !active ? "#5600ff" : "white",
              color: !active ? "white" : "black",
              border: "1px solid black",
          }}>לא פעיל</button>
      </div>)}
      {/* </div> */}
      {dataColmuns.filterByDate && <button onClick={() => setBolunData(!bolunData)}>סינון לפי תאריך</button>}
      {dataColmuns.filterByDate && bolunData &&
       <div className="data">
          <FilterData dateFilter={dateFilter} setDateFilter={setDateFilter}/>
          <button onClick={() => setBolunData(false)}>סגור</button>
        </div>}

      {spiner === true && (
        <div className="divlod">
          <div className="loader"></div>
        </div>
      )}
      <div style={{ alignItems: "center" }}>
        <h3 style={{ textAlign: 'center' }}>{hbNameTable}</h3>
        {dataColmuns.filterByDate && dateFilter && <p>סינון מתאריך: {dateFilter[0].value} עד:  {dateFilter[1].value.slice(0, 10)}<button style={{backgroundColor: "white", color:' black'}} onClick={()=>{setDateFilter(undefined)}}>נקה סינון תאריך</button></p>}
        <button onClick={() => setChooseColmuns(!chooseColmuns)}>בחירת עמודות</button>
        <ChooseColmuns listColmuns={dataColmuns.list_colmuns} colmuns={colmuns} setColmuns={setColmuns} setChooseColmuns={setChooseColmuns} table={table} chooseColmuns={chooseColmuns} />
        {rows.length > 0 && dataColmuns.list_colmuns && (
          <>
            <Table
              titles={dataColmuns.list_colmuns}
              colmuns={colmuns}
              rows={rows}
              sums={sums}
              table={table}
              hndelSort={hndelSort}
              isEdit={isEdit}
              permission={role}
              permissionID={id}
              storePayLod ={storePayLod}
              Unloading={Unloading}
              setPopUpForAddCards={setPopupForAddCards}
              setPopupForRemoveCards={setPopupForRemoveCards}
              deleteReportRow={deleteReportRow}
            />
          </>
        )}
      </div>
      <IndexPage page={page} pages={sumOfRows} hndelPage={hndelPage} />
      {popupForAddCards > 0 && <AddCards setPopupForAddCardsOpen={setPopupForAddCards} clubID={popupForAddCards} />}
      {popupForRemoveCards > 0 && <RemoveCards setPopupForRemoveCardsOpen={setPopupForRemoveCards} clubID={popupForRemoveCards} />}
      {unloading.st &&

<div style={{ fontSize: "10px", position: 'fixed', border: '1px black solid', top: "40%", right: "40%", backgroundColor: "white", borderRadius: '5px' }}>
  <p>אתה בטוח שברצונך לבטל את הטעינה</p>
  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>

    <button onClick={UnloadingReq}>אישור </button><button onClick={() => setUnloading({ st: false, obj: {} })}>ביטול</button>
  </div>
</div>
}
    </div>
  );
}

export default PageTable;