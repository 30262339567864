import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import './WhatsAppButton.css';

const WhatsAppButton = () => {

  return (
    <div className="whatsapp-icon">
    <a href={`https://wa.me/972732379998`} target="_blank" rel="noopener noreferrer">
      <FontAwesomeIcon icon={faWhatsapp} size="3x" color='green'/>
    </a>
    </div>
  );
};

export default WhatsAppButton;
