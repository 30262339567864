import { useState } from 'react';
import { Link, useNavigate, useParams } from "react-router-dom";
import './Login.css';
import { Connection } from './connection';
import AutoHideAlert from '../alrt';
import url from '../url ';
// import RECAPTCHA from './reCAPTCHA';
import ReCAPTCHA from "react-google-recaptcha";
import { useRef } from 'react';
import ReactRecaptcha3 from 'react-google-recaptcha3';
import { useEffect } from 'react';




function Login(props){
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
    const [data, setData] = useState('')
    const [con, setCon] = useState(false)
    const [showPassword, setShowPassword] = useState(false);
    const [isAlrt, setIsAlrt] = useState(false)
    const [message, setmessage] = useState('')
    const [isbot , setIsBot] = useState(false)
    const [Spiner, setSpiner] = useState(false);
    const navigate = useNavigate();
    const location = window.location;
    const searchParams = new URLSearchParams(location.search);
    const tokenForKeilot = searchParams.get("tokenForKeilot");
    const navigateTo = searchParams.get("navigateTo");
    
    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const recaptcha = useRef ( ) ; 


    // קוד בשביל סמארטפון
    window.addEventListener('resize', () => {
      setIsMobile(window.innerWidth < 768);
    });
  
    const handleEmailChange = (event) => {
      setEmail(event.target.value);
    };
  
    const handlePasswordChange = (event) => {
      setPassword(event.target.value);
    };
    useEffect(() => {
      ReactRecaptcha3.init(process.env.REACT_APP_RECAPTCHA_V3_SITE_KEY).then(
        (status) => {
          console.log(status);
        },(error) => {
          console.log(error);
        }
      ); 
    }, [])
  
    const handleSubmit = async(event) => {
      event.preventDefault();
      setSpiner(true)
      let v3 ;
      let v2 ;
      try {
      if(isbot){
        const captchaValue = recaptcha.current.getValue();
        if (!captchaValue) {
          // alert("Please verify the reCAPTCHA!");
          setmessage("  ")
          setIsAlrt(true)
          setTimeout(() => {
            setmessage("")
          setIsAlrt(false)
          }, 5000);
          return
        } 
        else{
          v2 = captchaValue
        }
      }else{
        const token = tokenForKeilot || await ReactRecaptcha3.getToken()
        v3 = token
        // console.log(token);
        // console.log(site);
      }
      
      
        const response = await fetch(`${url}/user/login `, {
          method: "post",
          credentials: 'include',
          headers: {
            "Content-Type": "application/json", // Specify the content type if sending JSON data.
          },
          body: JSON.stringify({
            mail:email,
            password: password, 
            statusV3 : v3,
            statusV2 : v2
          }),
        });
        const data = await response.json();
        setSpiner(false)
        if (data.message === 'ok') {
          setData(data)
          props.setUserData(data)
          setCon(true)
          navigate(navigateTo ? navigateTo : '/connection')
          // localStorage.setItem("name", data.name);
          localStorage.setItem("data", JSON.stringify(data));


          return
          // window.location.href = "/admin?table=cards";
          // alert("המשתמש נוצר בהצלחה")
        } else if (data.message === 'need 2fa') {
          navigate('/TwoStepsAuthenticat')
        } else {
          if(data.error === "need authenticate"){
            setIsBot(true)
            setmessage(data.error)
            setIsAlrt(true)
            setTimeout(() => {
              setmessage("")
            setIsAlrt(false)
            }, 5000);
            return
          }
          // console.log(data.massenge);
          // errMessage(data.message)
          console.error("Request failed with status:", response.status);
          // alert(data.massenge)
          setmessage(data.error)
          // setmessage("שגיאה נסה שוב")
          setIsAlrt(true)
          setTimeout(() => {
            setmessage("")
          setIsAlrt(false)
          }, 5000);
          return
        }
        // console.log("Response from server:", data.message);
      } catch (error) {
        console.error("An error occurred:", error);
        setmessage("שגיאה נסה שוב")
        setIsAlrt(true)
        setTimeout(() => {
          setmessage("")
        setIsAlrt(false)
        }, 5000);
      }
      setmessage("שגיאה נסה שוב")
      setIsAlrt(true)
      setTimeout(() => {
        setmessage("")
      setIsAlrt(false)
      }, 5000);
    };
    
    return (
      <div className="App" >
        {isAlrt&& 
        
        <AutoHideAlert message={message} />
        }
        <h1>קהילות קארד</h1>
        {! con && <form onSubmit={handleSubmit} style={{ width:isMobile ? "70%" : "25%"}}>
        <h3>התחברות</h3>
          <div className="form-group">
            <label htmlFor="email">  הכנס כתובת מייל:</label>
            <input
            //  style={{width :'70%'}}
              type="text"
              id="email"
              name="phone"
              value={email}
              onChange={handleEmailChange}
              required
            />
          </div>
          <div className="form-group password-container">
            <label htmlFor="password">סיסמה:</label>
            <div className="password-input-container">
            <input
            // style={{width :'70%'}}
              type={showPassword ? 'text' : 'password'}
              id="password"
              name="password"
              value={password}
              onChange={handlePasswordChange}
              className="password-input"
              required
              style={{paddingLeft: '0%'}}
            />
            <button type='button' style={{margin:'0%' ,padding :'0%', backgroundColor :'rgb(245, 185, 161)', position : 'absolute'}} className="password-eye"  onClick={togglePasswordVisibility}>👁️</button>
            </div>
            <Link to={`/phone-login${tokenForKeilot ? `?tokenForKeilot=${tokenForKeilot}` : ''}`}>כניסה באמצעות קוד לפלאפון</Link>
            <br />
            <Link to={`/forgotPassword${tokenForKeilot ? `?tokenForKeilot=${tokenForKeilot}` : ''}`}>שכחת סיסמתך לחץ כאן</Link>
          </div>
          {/* <RECAPTCHA /> */}
         {isbot &&  <ReCAPTCHA sitekey={process.env.REACT_APP_RECAPTCHA_V2_SITE_KEY} ref = { recaptcha }/>}
         {Spiner === true ?(
                <div className="divlod">
                  <div className="loader"></div>
                </div>) :
          <button type="submit">כניסה</button>}

          <br></br>
          <Link to={`/signUp${tokenForKeilot ? `?tokenForKeilot=${tokenForKeilot}` : ''}`}>לא רשום? לחץ כאן</Link>
        </form>}
        {con && <Connection obj={data} setUser={props.setUser}/>}

      </div>
    );
  }
  
 
export default Login;