import React, { useState, useEffect } from 'react';
import { LightAsync as SyntaxHighlighter } from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';

const LogViewer = () => {
  const [logData, setLogData] = useState('');

  useEffect(() => {
    fetch('http://localhost/log')
      .then((res) => res.text())
      .then((data) => setLogData(data));
  }, []);

  return (
    <div style={{ direction: 'ltr' }}>
    <SyntaxHighlighter language="plaintext" style={docco}>
      {logData}
    </SyntaxHighlighter>
    </div>
  );
};

export default LogViewer;
