import "./indexPage.css";

function IndexPage(props) {
  return (
    <div className="indexPage">
      <ul>
        <li>
          <button
            className="next"
            style={{}}
            onClick={() => props.hndelPage(props.page - 1)}
          >
            &lt;
          </button>
        </li>
        {Math.ceil(props.pages / 30 ) < 4
          ? Array.from({ length: Math.ceil(props.pages / 30) }, (_, i) => (
              <li className="ll" key={i}>
                <button
                  className="next"
                  style={{
                    backgroundColor: i === props.page ? "#5600ff" : "white",
                  }}
                  onClick={() => props.hndelPage(i)}
                >
                  {i + 1}
                </button>
              </li>
            ))
          : Array.from(
              { length: 6 },
              (_, i) =>
                i + props.page - 3 > -1 &&
                i + props.page - 3 < Math.ceil(props.pages / 30 ) && (
                  <li className="ll" key={i}>
                    <button
                      className="next"
                      style={{
                        backgroundColor:
                          i + props.page - 3 === props.page
                            ? "#5600ff"
                            : "white",
                      }}
                      onClick={() => props.hndelPage(i + props.page - 3)}
                    >
                      {i + 1 + props.page - 3}
                    </button>
                  </li>
                )
            )}   
        <li>
          <button
            className="next"
            style={{}}
            onClick={() => props.hndelPage(props.page + 1)}
          >
           
            &gt;{" "}
          </button>
        </li>
      </ul>
      <p>סך הכל {props.pages} שורות</p>
    </div>
  );
}
export default IndexPage;
