import React, { useEffect, useState } from 'react';
import './SelectSearch.css'
import url from '../../../url ';
import { useLocation } from 'react-router-dom';


function SelectSearch2(props) {
  const { role, id, required } = props;
  const [inpotValue, setinpotValue] = useState(props.defaultValue?props.defaultValue:"");
  const [filter, setFilter] = useState("a")
  const [options, setOptions] = useState({})
  const [butTrue, setButTrue] = useState(false)

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  let permission = role || searchParams.get("permission");
  const permissionID = id || searchParams.get("permissionID");
  if(permission !== 'admin'){
    permission = `${permission}/${permissionID}`
  }


  useEffect(() => {
    setinpotValue(props.defaultValue ? props.defaultValue : "");
}, [props.defaultValue]);

  const req = async () => {
    try {
      let res = await fetch(`${url}/${permission}/find-prams-for-colmun/${props.table}/${props.colmun}?filterSherch=${filter}`, {
        credentials: 'include'
      });
      let data = await res.json();
      let obj = convertArrayToObject(data);
      console.log(obj);
      setOptions(obj);
      return data
    }
    catch (err) {
      console.log(err);
    }
  }
  useEffect(() => {
    let data = req();

  }, [filter])
  const hndelinpot = (e) => {
    setinpotValue(e.target.value)
    setFilter(e.target.value)
    setButTrue(true)
  }
  const hndelbut = (obj) => {
    setinpotValue(obj.name);
    props.hndel(obj.value, props.raelName, obj.name)
    setButTrue(false)
  }
  return (
    <div className='SelectSearch'>


      <label htmlFor='nn'>{props.name}</label>
      <input
        type='text'
        name='nn'
        id='nn'
        onChange={(e) => hndelinpot(e)}
        value={inpotValue}
        autoComplete="off"
        required={required}
      />
      
        {butTrue &&<div className='s2'>  {options.map((obj) => {
          return<button key={obj.value} onClick={() => hndelbut(obj)} type='button'>{obj.name}</button>
        })}
      </div>}
    </div>
  );
}
function convertArrayToObject(arr) {
  // יצירת מערך ריק לכל האובייקטים
  let result = [];

  // עבר בכל תת מערך שבמערך הנתון והמרתו לאובייקט
  for (let i = 0; i < arr.length; i++) {
    let subArray = arr[i];
    let name = subArray[0].trim(); // הסרת רווחים משני הצדדים של השם
    let value = subArray[1];

    // יצירת אובייקט חדש והוספתו למערך
    result.push({ name, value });
  }

  return result;
}
export default SelectSearch2;





