import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import url from "../url ";

export const CreateApiKey = () => {
    const [apiKey, setApiKey] = useState("");
    const [name, setName] = useState("");
    const navigate = useNavigate();

    useEffect(() => {
        const getNameAndConnectAs = async ()=>{
          try {
            const res = await fetch(`${url}/user/get-name`, {
              credentials: 'include',
              method: 'post'
            });
            if(res.status === 401 || res.status === 403){
              navigate('/login?navigateTo=/createApiKey')
            }
            const data = await res.json()
            setName(data.name)
            console.log(data);
          }
          catch (e) {
            console.error(e);
          }
        }
        getNameAndConnectAs()
    }, [])
    const createApiKey = async () => {
        const response = await fetch(`${url}/user/createApiKey`, {
            method: "POST",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({}),
        });
        const data = await response.json();
        setApiKey(data.apiKey);
    };

    const copyToClipboard = () => {
        navigator.clipboard.writeText(apiKey).then(() => {
            alert("המפתח הועתק בהצלחה");
        }, (err) => {
            console.error('Could not copy text: ', err);
        });
    };

    return <div>
        <h1>שלום {name}</h1>
        <h1>קבלת מפתח וזיהוי משתמש ל API</h1>
        <p>כאן תוכל לקבל מפתח וזיהוי משתמש לשימוש ב API
            <br />
            יש לשים לב שברגע שיוצרים מפתח חדש המפתח הקודם נמחק ולא יהיה ניתן להשתמש בו יותר
        </p>
        <button onClick={createApiKey}>קבל מפתח</button>
        {apiKey && <div>
            <h2>המפתח שלך הוא: {apiKey}</h2>
            <button onClick={copyToClipboard}>העתקת המפתח</button>
            <p>המפתח לא יוצג שוב, יש לשמור אותו במקום בטוח</p>
        </div>}
    </div>;
};