import { useState } from "react";

import "./Login.css";
import AutoHideAlert from "../alrt";
import url from "../url ";
import ReCAPTCHA from "react-google-recaptcha";
import { useRef } from "react";
import ReactRecaptcha3 from "react-google-recaptcha3";
import { useEffect } from "react";

function PhoneLogin() {
  const [email, setEmail] = useState("");
  const [code, setCode] = useState("");
  const [Spiner, setSpiner] = useState(false);

  const [from, setFrom] = useState(true);
  const [err, setErr] = useState("");
  const [errOn, setErrOn] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [isAlrt, setIsAlrt] = useState(false);
  const [message, setmessage] = useState("");
  const [isbot, setIsBot] = useState(false);
  const location = window.location;
  const searchParams = new URLSearchParams(location.search);
  const tokenForKeilot = searchParams.get("tokenForKeilot");

  const recaptcha = useRef();

  // קוד בשביל סמארטפון
  window.addEventListener("resize", () => {
    setIsMobile(window.innerWidth < 768);
  });

  const errMessage = (message) => {
    setErr(message);
    setErrOn(true);
    setEmail("");
    
    setTimeout(() => {
      setErrOn(false);
      
    }, 5000);
  };
  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };
  useEffect(() => {
    ReactRecaptcha3.init(process.env.REACT_APP_RECAPTCHA_V3_SITE_KEY).then(
      (status) => {
        console.log(status);
      },
      (error) => {
        console.log(error);
      }
    );
  }, []);

  const handleSubmit1 = async (event) => {
    event.preventDefault();
    setSpiner(true);
    let v3;
    let v2;
    
    try {
    if (isbot) {
      const captchaValue = recaptcha.current.getValue();
      if (!captchaValue) {
        // alert("Please verify the reCAPTCHA!");
        setmessage("  ");
        setIsAlrt(true);
        setTimeout(() => {
          setmessage("");
          setIsAlrt(false);
        }, 5000);
        return;
      } else {
        v2 = captchaValue;
      }
    } else {
      const token = tokenForKeilot || await ReactRecaptcha3.getToken();
      v3 = token;
      console.log(token);
      // console.log(site);
    }

      const response = await fetch(`${url}/user/sent-code/phone-login`, {
        method: "post",
        credentials: 'include',
        headers: {
          "Content-Type": "application/json", // Specify the content type if sending JSON data.
        },
        body: JSON.stringify({
          phone: email,
          statusV3: v3,
          statusV2: v2,
        }),
      });
      const data = await response.json();
      setSpiner(false);
      if (response.ok) {
        setFrom(false);
      } else {
        if (data.error === "need authenticate") {
          setIsBot(true);
          setmessage(data.error);
          setIsAlrt(true);
          setTimeout(() => {
            setmessage("");
            setIsAlrt(false);
          }, 5000);
          return;
        }
        errMessage(data.error);
        console.error("Request failed with status:", response);
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  async function handleSubmit2(event) {
    event.preventDefault();
    setSpiner(true);
    try {
      const response = await fetch(`${url}/user/phone-code-login`, {
        method: "post",
        credentials: 'include',
        headers: {
          "Content-Type": "application/json", // Specify the content type if sending JSON data.
        },
        body: JSON.stringify({
          phone: email,
          code: code,
        }),
      });
      const data = await response.json();
      setSpiner(false);
      console.log(data);
      if (data.message === 'ok') {
        setFrom(false);
        window.location.href = "/";
      } else {
        errMessage(data.error);
        console.error("Request failed with status:", response);
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  }
  
  return (
    <div className="App">
      {isAlrt && <AutoHideAlert message={message} />}
      <h1> קהילות קארד</h1>
      <h3>כניסה באמצעות קוד לפלאפון</h3>
      {from && (
        <form
          onSubmit={handleSubmit1}
          style={{ width: isMobile ? "70%" : "25%" }}
        >
          <div className="form-group">
            <label htmlFor="phone">הקלד פלאפון</label>
            <input
              type="text"
              id="phone"
              name="phone"
              value={email}
              onChange={handleEmailChange}
              required
            />
          </div>
          {isbot && (
            <ReCAPTCHA
              sitekey={process.env.REACT_APP_RECAPTCHA_V2_SITE_KEY}
              ref={recaptcha}
            />
          )}
          {Spiner === true ? (
            <div className="divlod">
              <div className="loader"></div>
            </div>
          ) : (
            <button type="submit">התקשר אליי</button>
          )}
          <br />
          {errOn && <h6 className="err">{err}</h6>}
        </form>
      )}
      {!from && (
        <form
            style={{ width: isMobile ? "70%" : "25%" }}
            onSubmit={handleSubmit2}
            >
            <div className="form-group">
              <label htmlFor="code">הקלד קוד</label>
              <input
                type="text"
                id="code"
                name="code"
                value={code}
                onChange={(e) => setCode(e.target.value)}
                required
              />
            </div>
          {Spiner === true ? (
            <div className="divlod">
              <div className="loader"></div>
            </div>
            ) : (
                <button type="submit">כניסה</button>
            )}
            <br />
            {errOn && <h6 className="err">{err}</h6>}
        </form>
    )}
    </div>
  );
}

export default PhoneLogin;
