import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import url from '../url ';
import "./main.css";
import Panelnformation from "./panelnformation/panelnformation";
import PageTable from "./table/pageTable";

function Main(props) {
  const { role } = props
  const { id } = useParams()
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const table1 = searchParams.get("table");
  const [name, setName] = useState('')
  const [connectAs, setConnectAs] = useState('')
  
  

  const [table] = useState(table1);
  const navigate = useNavigate();

  const handlLoguot = async () => {
    try {
      await fetch(`${url}/user/logout`, { 
        credentials: 'include',
        method: "POST" });
    } catch (e) {
      console.log(e.message);
    }
  };
  useEffect(() => {
  const getNameAndConnectAs = async ()=>{
    try {
      const res = await fetch(`${url}/${role + (id? `/${id}`:'')}/get-name-and-connect-as`, {
        credentials: 'include',
        method: 'post'
      });
      if(res.status === 401 || res.status === 403){
        navigate('/login')
      }
      const data = await res.json()
      setName(data.name)
      setConnectAs(data.connectAs)
      console.log(data);
    }
    catch (e) {
      console.error(e);
    }
  }
  getNameAndConnectAs()
  }, [role, id])
  return (
    <div>
          <div className="dt">
        <div
          style={{ textAlign: "right", width: "40%", display: "inline-block" }}
        >
          <a href="/login" onClick={handlLoguot} className="loguot">
            יציאה
          </a>
          <button style={{backgroundColor:"white", border:"", color:'black'}} onClick={()=> navigate("/Connection")}>אפשריות כניסה</button>
          {role === 'storeManager' && <button style={{backgroundColor:"white", border:"", color:'black'}} onClick={()=> navigate(`/CardCode/${id}/money`)}>מעבר למסך מכירה</button>}
        </div>
        <div style={{ textAlign: "center", width: "20%", display: "inline-block" }}>
        <h3>{name}</h3>
        <h3>{connectAs}</h3>
      </div>
        <div
          style={{ textAlign: "left", width: "40%", display: "inline-block" }}
        >
          <div style={{ display: "inline-block" }}>
            <img src="/zz.png" alt="סמל" width="150" height="60" />
          </div>
        </div>
      </div>
      <Panelnformation permission={role + (id? `/${id}`:'')}/>
      <br />
      <PageTable table={table} role={role} id={id}/>
    </div>
  );
}
export default Main;
