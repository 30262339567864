import React from 'react';
import './table.css';
import { Link } from "react-router-dom";
import url from '../../url ';

function Table(props) {
    const   {permission, permissionID, table, titles, rows, hndelSort, isEdit, alrt, Unloading, storePayLod, sums, colmuns, setPopUpForAddCards, setPopupForRemoveCards, deleteReportRow} = props;
    const ok = async (ok, id) => {
        let url1 ;
        if(permission === 'admin'){
            url1 = `${url}/${permission}/chengeSttusOfLoad/${table}`
        }
        else{
            url1 = `${url}/${permission}/${permissionID}/chengeSttusOfLoad/${table}`
        }
        try {
            const res = await fetch(url1, {
                method: "POST",
                credentials: 'include',
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    status: ok,
                    id: id
                }),
            }

            )
            await res.json()
            if (res.ok) {
                alert('הצליח')
                window.location.reload();
                return;
            }
        }
        catch (e) {
            console.log(e);
        }
        alert('אירעה שגיאה הסה שוב')
    }
    return (
        <div>
            <div className='rrr'>
                <table >
                    <thead>
                        <tr >
                            {permission === 'storep' && <th>ביטול קניה</th>}
                            {permission !== 'storep' && <th> בחירה</th>}
                            {titles.map((title, index) => {
                                if ((permission === 'club' && table === 'store_club_agreements_for_sid' && title === "store_id") || (colmuns && !colmuns.includes(title))) {
                                    return null; // or you can use an empty <></> if you want an empty fragment
                                }

                                return (
                                    <th key={index}>
                                        <div style={{ display: "flex", alignItems: 'center', justifyContent: 'center' }}>
                                            <div style={{ display: "inline-block", marginLeft: '5px' }}>
                                                {title}
                                            </div>
                                            <div style={{ display: "flex", alignItems: 'center' }}>
                                                <button style={{ padding: '0px', backgroundColor: 'white', color: 'black' }} onClick={() => hndelSort(title)}> ↓↑</button>
                                            </div>
                                        </div>
                                    </th>
                                );
                            })}

                            {isEdit && <th> עריכת רשומה</th>}
                            {((permission === 'club' && ['cards_load', 'vouchers_units_cards'].includes(table)) || (permission === 'storeManager' && table === 'vouchers_transactions'))
                             && <th>ביטול טעינה</th>}
                            {permission === 'club' && table === 'store_club_agreements_for_sid' && <th>תשלום לחנות עבור התחייבות</th>}
                            {permission === 'admin' && ['clubs', 'stores', 'recruiters'].includes(table) && <th> מעבר לדף ניהול</th>}
                            {permission === 'admin' && table === 'reports_stores' && <th>מחיקת שורה</th>}
                            {permission === 'admin' && table === 'clubs' && <>
                                <th> הוספת כרטיסים</th>
                                <th> הסרת כרטיסים</th>
                            </>}
                        </tr>

                    </thead>
                    <tbody>
                        {rows.map((row, rowIndex) => (
                            <tr key={rowIndex}>
                                {permission !== 'storep' && <td><input type='checkbox'/></td>}
                                {permission === 'storep' && <td> {row['סטטוס'] !== "בוטל" && <button style={{ backgroundColor: 'white', borderRadius: '10px', padding: '5px', border: '1px black solid', color: 'black', fontSize: '10px' }} onClick={() => alrt(row.id)}>ביטול</button>}</td>}
                                {Object.keys(row).map((cell, cellIndex) => {
                                    if ((permission === 'club' && table === 'store_club_agreements_for_sid' && cell === "store_id") || (colmuns && !colmuns.includes(cell))) {
                                        return null; // or you can use an empty <></> if you want an empty fragment
                                    }

                                    return (
                                        <td key={cellIndex}>
                                            {row[cell] === "true" ? <span>v</span> :
                                                row[cell] === "false" ? <span>x</span> :
                                                    (row[cell] === 'ממתין לאישור' && (
                                                    (permission === 'storeManager' && table === 'club_store_payments') ||
                                                    (permission === 'club' && table === 'vouchers_transactions')
                                                )) ?
                                                        <div>
                                                            <button className='bok' onClick={() => ok('מאושר', row.id)}>אישור</button>
                                                            <button className='bok' onClick={() => ok('נדחה', row.id)}>ביטול</button>
                                                        </div> :
                                                    (row[cell] === 'ממתין לאישור' && (permission === 'admin' && (table ==='club_load_history'
                                                        || table === 'store_pay_to_keilotkard'
                                                    ))) ?
                                                        <div>
                                                            <button className='bok' onClick={() => ok('1', row.id)}>אישור</button>
                                                            <button className='bok' onClick={() => ok('0', row.id)}>ביטול</button>
                                                        </div> : <span>{row[cell]}</span>}
                                        </td>
                                    );
                                })}

                                {isEdit && <td><Link to={{ pathname: "/edit", search: `?table=${table}&id=${row.id}&permission=${permission}&permissionID=${permissionID}` }}><button style={{ backgroundColor: 'white', borderRadius: '10px', padding: '5px', border: '1px black solid', color: 'black', fontSize: '10px' }}>ערוך</button></Link></td>}
                                {((permission === 'club' &&  ['cards_load', 'vouchers_units_cards'].includes(table)) || (permission === 'storeManager' && table === 'vouchers_transactions'))
                                && row['סטטוס'] !== "בוטל" &&
                                    <td><button style={{ backgroundColor: 'white', borderRadius: '10px', padding: '5px', border: '1px black solid', color: 'black', fontSize: '10px' }} onClick={() => Unloading(row)}>ביטול </button></td>
                                }
                                {permission === 'club' && table === 'store_club_agreements_for_sid' &&
                                    <td><button style={{ backgroundColor: 'white', borderRadius: '10px', padding: '5px', border: '1px black solid', color: 'black', fontSize: '10px' }} onClick={() => {storePayLod(row.store_id, row['חוב עבור התחייבות']  )}}>תשלום </button></td>
                                }
                                {permission === 'admin' && table === 'clubs'  &&  
                                    <>
                                        <td><button style={{ backgroundColor: 'white', borderRadius: '10px', padding: '5px', border: '1px black solid', color: 'black', fontSize: '10px' }} onClick={() => window.location.href = `/club/${row.id}?table=cards`}>מעבר</button></td>
                                        <td><button style={{ backgroundColor: 'white', borderRadius: '10px', padding: '5px', border: '1px black solid', color: 'black', fontSize: '10px' }} onClick={() => setPopUpForAddCards(row.id)}>הוסף</button></td>
                                        <td><button style={{ backgroundColor: 'white', borderRadius: '10px', padding: '5px', border: '1px black solid', color: 'black', fontSize: '10px' }} onClick={() => setPopupForRemoveCards(row.id)}>הסר</button></td>
                                    </>
                                }
                                {permission === 'admin' && table === 'stores'  &&  <td><button style={{ backgroundColor: 'white', borderRadius: '10px', padding: '5px', border: '1px black solid', color: 'black', fontSize: '10px' }} onClick={() => window.location.href = `/storeManager/${row.id}?table=store_club_agreements`}>מעבר</button></td>}
                                {permission === 'admin' && table === 'recruiters'  &&  <td><button style={{ backgroundColor: 'white', borderRadius: '10px', padding: '5px', border: '1px black solid', color: 'black', fontSize: '10px' }} onClick={() => window.location.href = `/recruiter/${row.id}?table=report_for_recruiters`}>מעבר</button></td>}
                                {permission === 'admin' && table === 'reports_stores' && <td><button style={{ backgroundColor: 'white', borderRadius: '10px', padding: '5px', border: '1px black solid', color: 'black', fontSize: '10px' }} onClick={() => {deleteReportRow(row.id)}}>מחק</button></td>}
                            </tr>

                        ))}
                        {sums && Object.keys(sums).length > 0 && <tr>
                            <td>סה"כ</td>
                            {titles.map((cell, cellIndex) => {
                                if ((colmuns && !colmuns.includes(cell))) {
                                    return null; // or you can use an empty <></> if you want an empty fragment
                                }
                                if (cell in sums) {  
                                    return (
                                        <td key={cellIndex}>
                                            {sums[cell]}
                                        </td>
                                    );
                                } else {
                                    return <td key={cellIndex}></td>
                                }
                            })}
                        </tr>}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default Table;

