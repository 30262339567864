import React, { useEffect, useState } from 'react';
import Plot from 'react-plotly.js';
import url from '../url ';



const MyPlot = () => {
    const [months, setMonths] = useState([]);
    const [amounts, setAmounts] = useState([]);
    useEffect(() => {
        const getDates = async () => {
            const getDates = async () => {
                const res = await fetch(url + `/admin/get-table-info/card_usage_history/rows`, {
                    method: "post",
                    credentials: 'include',
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        filter: {'1active':[{ type: '=', value: 'פעיל', relation: 'and'}]},
                        getSum: false,
                        }),
                })
                const data = await res.json()
                return data.rows;
            };
            
            const groupByMonth = (data) => {
                const groupedData = {};
                data.forEach((item) => {
                    const month = item['תאריך שימוש'].substring(0, 8); // Get YYYY-MM
                    if (!groupedData[month]) {
                        groupedData[month] = 0;
                    }
                    groupedData[month] += item['סכום קניה'];
                });
                return groupedData;
            };
            
            // Group data
            const groupedData = groupByMonth(await getDates());
            const months = Object.keys(groupedData);
            const amounts = Object.values(groupedData);
            setMonths(months);
            setAmounts(amounts);
        }
        getDates();
    }, []);
    return (
        <Plot
            data={[
                {
                    type: 'bar',
                    x: months,
                    y: amounts,
                },
            ]}
            layout={{
                title: 'Monthly Data',
                xaxis: {
                    title: 'Month',
                },
                yaxis: {
                    title: 'Amount',
                },
            }}
        />
    );
};

export default MyPlot;