import { useState, useEffect  } from "react";
import "./filterData.css";



function FilterData(props) {
    const { dateFilter, setDateFilter } = props;
    const [filterSt, setFilterSt] = useState('')
    const [filterFi, setFilterFi] = useState('')

    // useEffect(() => {
    //   if (myVar) {
    //    hndelFilter()
    //     setMyVar(false);
    //   }
    // }, [myVar]);
    useEffect(() => {
        console.log(dateFilter);
        if (dateFilter) {
            setFilterSt(dateFilter[0].value)
            setFilterFi(dateFilter[1].value.split(' ')[0])
            return;
        }
      }, [dateFilter]);

      const getMonthStartEnd = (date) => {
        const start = new Date(date.getFullYear(), date.getMonth(), '2');
        const end = new Date(date.getFullYear(), date.getMonth() + 1);
        return { start, end };
    };

    const getYearStartEnd = (date) => {
        const start = new Date(date.getFullYear(), 0, 2);
        const end = new Date(date.getFullYear(), 11, 32);
        return { start, end };
    };

    const handleMonthNavigation = (direction) => {
        const currentDate = new Date(filterSt || new Date().toISOString().slice(0, 10));
        currentDate.setMonth(currentDate.getMonth() + direction);
        const { start, end } = getMonthStartEnd(currentDate);
        setFilterSt(start.toISOString().slice(0, 10));
        setFilterFi(end.toISOString().slice(0, 10));
    };

    const handleYearNavigation = (direction) => {
        const currentDate = new Date(filterSt || new Date().toISOString().slice(0, 10));
        currentDate.setFullYear(currentDate.getFullYear() + direction);
        const { start, end } = getYearStartEnd(currentDate);
        setFilterSt(start.toISOString().slice(0, 10));
        setFilterFi(end.toISOString().slice(0, 10));
    };

    const setCurrentMonth = () => {
        const { start, end } = getMonthStartEnd(new Date());
        setFilterSt(start.toISOString().slice(0, 10));
        setFilterFi(end.toISOString().slice(0, 10));
    };

    const setCurrentYear = () => {
        const { start, end } = getYearStartEnd(new Date());
        setFilterSt(start.toISOString().slice(0, 10));
        setFilterFi(end.toISOString().slice(0, 10));
    };

    const handleFilter = () => {
        setDateFilter ([{ type: '>=', value: filterSt, relation: 'and' }, { type: '<=', value: `${filterFi} 23:59:59`, relation: "and" }]);
    }

    return (
        <div >
            <h3>סינון לפי תאריך</h3>
            <label htmlFor="st">בחר תאריך התחלתי</label>
            <input type="date" id="st" value={filterSt} onChange={(e) => setFilterSt(e.target.value)} />
            <label htmlFor="fi">בחר תאריך סיום</label>
            <input type="date" id="fi" value={filterFi} onChange={(e) => setFilterFi(e.target.value)} />
            <div>
                <div className="btn-move">
                <button onClick={() => handleMonthNavigation(-1)}>&lt; חודש</button>
                <button onClick={() => setCurrentMonth()}>חודש נוכחי</button>
                <button onClick={() => handleMonthNavigation(1)}>חודש &gt;</button>
                </div>
                <div className="btn-move">
                <button onClick={() => handleYearNavigation(-1)}>&lt; שנה</button>
                <button onClick={() => setCurrentYear()}>שנה נוכחית</button>
                <button onClick={() => handleYearNavigation(1)}>שנה &gt;</button>
                </div>
            </div>
            <input type="submit" value="מיין" onClick={handleFilter} />
        </div>
    )
}
export default FilterData


