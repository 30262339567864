import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import "./Add.css";
import MultySelect from "./SelectSearch/multySelect";
import SelectSearch2 from "./SelectSearch/SelectSearch2";
import url from "../../url ";
import { Checkbox } from "@mui/material";

function Add() {
  const [data, setData] = useState("");
  const [post, setPost] = useState({});
  const [selectedOption, setSelectedOption] = useState(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [spiner, setSpiner] = useState(false);
  const [disclick, setDisclick] = useState(false)
  const [options, setOptions] = useState([])
  const [excelUdate, setExcelUdate] = useState(false)
  const [selectedImage, setSelectedImage] = useState(null);

  const handleImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      setSelectedImage(event.target.files[0]);
    }
  };


  // קוד בשביל סמארטפון
  window.addEventListener('resize', () => {
    setIsMobile(window.innerWidth < 768);
  });

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const table = searchParams.get("table");
  let permission = searchParams.get("permission");
  const permissionID = searchParams.get("permissionID");
  if (permission !== 'admin') {
    permission = `${permission}/${permissionID}`
  }

  const req = async () => {
    setSpiner(true)
    let res = await fetch(`${url}/${permission}/add-raw/get/${table}`, {
      method: 'post',
      credentials: 'include'
    });
    let data = await res.json();
    // data
    setData(data);
    data.forEach((obj) => {
      let aa;
      if(obj.raelName === "פעיל"){
         aa = { [obj.raelName]: true };
      }
      else{
         aa = { [obj.raelName]: "" };
      }
      if (obj.type === "multy_select") {
        aa = { [obj.raelName]: [] };
      }


      setPost((prevPost) => ({ ...prevPost, ...aa }));
    });

    setSpiner(false)
  };

  const reqGrop = async () => {
    if (table !== 'cards') {
      return
    }
    try {
      let res = await fetch(`${url}/${permission}/find-groups-for-club/?club_id=${post.club_id}`, {
        credentials: 'include'
      });
      let data = await res.json();
      let obj = convertArrayToObject(data);
      setOptions(obj);
      return data
    }
    catch (err) {
      console.log(err);
    }
  }
  useEffect(() => {
    reqGrop()
  }, [post.club_id])

  useEffect(() => {
    req();
  }, []);
  const hndelSubmit = async (event) => {
    event.preventDefault();
    setDisclick(true)
    try {
      let newPost = { ...post };
      if (selectedOption && selectedOption['קטגוריית החנות'] === 'אחר') {
        newPost['קטגוריית החנות'] = 'new:' + post['קטגוריית החנות']
      }
      if (selectedOption && selectedOption['צורת התשלום'] === 'אחר') {
        newPost['צורת התשלום'] = 'new:' + post['צורת התשלום']
      }
      const response = await fetch(`${url}/${permission}/add-raw/add/${table}`, {
        method: "post",
        credentials: 'include',
        headers: {
          "Content-Type": "application/json", // Specify the content type if sending JSON data.
        },
        body: JSON.stringify(newPost),
      });
      const data = await response.json();
      if (data.status === "success") {
        let success = true;
        if (selectedImage) {
          const formData = new FormData();
          formData.append("photo", selectedImage);
          const res = await fetch(`${url}/${permission}/edit-photos/${table}?id=${data.id}`, {
            method: "post",
            credentials: 'include',
            body: formData
          });
          const resData = await res.json();
          if (resData.status === "success") {
            success = true;
          } else {
            success = false;
          }
        }
        if (success) {
          alert("הרשומה התוספה בהצלחה")
          window.history.back();
        } else {
          alert("אירעה שגיאה בהוספת התמונה נסה שוב")
        }
      } else {
        alert("אירעה שגיאה נסה שוב")
        console.error("Request failed with status:", response);
      }
    } catch (error) {
      console.error("An error occurred:", error);
      alert("אירעה שגיאה נסה שוב")
    }
    setDisclick(false)
  };
  const handleSelectChange = (e, raelName) => {
    let obj;
    setSelectedOption({...selectedOption, [raelName]: e.target.value});
    obj = { ...post, [raelName]: e.target.value };
    setPost(obj);
  };
  const hndel = (event, raelName, type) => {
    let obj;
    if (type === "checkbox") {
      obj = { ...post, [raelName]: event.target.checked };
      console.log(event.target.checked);
    }
    else {
      obj = { ...post, [raelName]: event.target.value };
    }
    console.log(event);
    setPost(obj);
  };
  const hndelSs = (value, raelName) => {
    let obj;
    obj = { ...post, [raelName]: value };
    setPost(obj);
  }
  console.log(post);

  // פונקציה להעלהת קובץ אקסל לשרת 
  const handleUpload = (event) => {
    const selectedFile = event.target.files[0];

    if (selectedFile) {
      const formData = new FormData();
      formData.append('file', selectedFile);

      fetch(`${url}/${permission}/add_raw_excel/upload/${table}?edit=${excelUdate}`, {
        method: 'POST',
        credentials: 'include',
        body: formData,
      })
        .then(response => response.json())
        .then(data => {
          if (data.status === 'success') {
            alert('הצליח')
            window.history.back()
            return
          }
          alert('אירעה שגיאה נסה שוב' + data.error)

          console.log('File uploaded successfully:', data);
          // ניתן להוסיף פעולות נוספות לאחר ההעלאה
        })
        .catch(error => {
          alert('אירעה שגיאה נסה שוב')
          console.error('Error uploading file:', error);
        });
    } else {
      console.log("No file selected");
      alert('לאנבחר קובץ')
    }
  };

  function downloadFile() {
    // Use fetch to send a POST request
    fetch(`${url}/${permission}/add_raw_excel/download/${table}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        credentials: 'include'
    })
    .then(response => response.blob()) // convert the response to a Blob
    .then(blob => {
        // Create a link element, use it to download the blob
        var a = document.createElement("a");
        a.href = URL.createObjectURL(blob);
        a.download = `balnk_to_${table}.xlsx`; // Set the file name.
        a.style.display = "none";

        // Append the link to the body, trigger click, remove the link
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    })
    .catch(error => console.error('Error:', error));
}


  return (
    <>
      {/* <img src="/zz.png" alt="סמל" width="150" height="60" /> */}

      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div style={{ width: "50%", height: '50px', display: "inline-block" }} >
          <button style={{}} onClick={() => window.history.back()}>X</button>
        </div>
        <div style={{ textAlign: "left", width: "50%", display: "inline-block" }} >
          <img src="/zz.png" alt="סמל" width="150" height="60" />
        </div>
      </div>

      <div className="add">
        <br />
        <form
          style={{ width: isMobile ? "70%" : "25%" }}
          className="pAdd"
          onSubmit={hndelSubmit}
        >
          <h1 className="titelAdd">רשומה חדשה</h1>
          <h3>הוספת רשומות באמצעות אקסל</h3>
          <button type="button" onClick={downloadFile}>הורדת קובץ אקסל להוספת רשומות </button>
          {/* <form action={`${domin}/admin/add_raw_excel/${table}`} method="post"> */}
          {table === 'cards' && (
          <div>
            אקסל עבור עדכון
            <Checkbox name="excelUdate" onChange={(e) => setExcelUdate(e.target.checked)} checked={excelUdate} /> 
          </div>
          )}
          <label htmlFor="fileInput" className="custom-file-upload">
            העלה קובץ אקסל
            <input id="fileInput" type="file" onChange={handleUpload} />
          </label>
          <h3>הוספה ידנית</h3>
          {spiner === true && (<div className="divlod"><div className="loader"></div></div>)}

          {data &&
            data.map((obj) => (
              <div key={obj.name}>

                {obj.type === "select" ?
                  <>
                    <label htmlFor="mySelect">{obj.name}</label>
                    <br />
                    <select
                      // style={{overflow: "scroll"}}
                      className="select"
                      id="mySelect"
                      value={selectedOption ? selectedOption[obj.raelName] : ''}
                      onChange={(e) => handleSelectChange(e, obj.raelName)}
                      required={obj.required}

                    // defaultValue={{value: "זכר",label:"זכר"}}
                    ><option value={''} label="אנא בחר"></option>
                      {obj.options.map((v) => {
                        if (v !== 'אחר') {
                          return <option value={v} key={v}>{v}</option>
                        }
                        return null
                      })}
                      {['קטגוריית החנות', 'צורת התשלום'].includes(obj.name) && (<option value={'אחר'} key={'אחר'}>אחר</option>)}
                      </select >
                      {['קטגוריית החנות', 'צורת התשלום'].includes(obj.name) && selectedOption && selectedOption[obj.raelName] === 'אחר' &&
                      (
                      <input type="text" name="category" required={obj.required} onChange={(event) => hndel(event, obj.raelName, obj.type)} value={post[obj.raelName]} />
                      )}
                      </> :
                  obj.type === "searchable" ?
                    <SelectSearch2 required={obj.required} name={obj.name} table={obj.table_for_sherch} colmun={obj.colmun_for_sherch} hndel={hndelSs} raelName={obj.raelName} /> :
                    obj.type === "multy_select" ?

                      < MultySelect required={obj.required} clubId={post.club_id} name={obj.name} hndel={hndelSs} raelName={obj.raelName} options={options}  /> :
                      obj.type === "photo" ?
                        <>
                          <label htmlFor="clubName">תמונה</label>
                          <br />
                          <div>
                            <input type="file" accept="image/*" required={obj.required} onChange={handleImageChange} />
                            {selectedImage && <img src={URL.createObjectURL(selectedImage)} alt="Selected" width="300" />}
                          </div>
                        </> :
                      (
                        <>

                          <label htmlFor="clubName">{obj.name}</label>
                          <br/>
                          <input
                            type={obj.type}
                            id="clubName"
                            name="clubName"
                            onChange={(event) => hndel(event, obj.raelName, obj.type)}
                            value={post[obj.raelName]}
                            maxLength={obj.maxlength}
                            checked = {post[obj.raelName]}
                            required = {obj.required && obj.type !== "checkbox"}
                          />
                          {(obj.name === "מחיר העסקה" && post['סוג המחיר']) && (
                            <>
                            <p>סה"כ {post[obj.raelName] * (post['סוג המחיר'] === 'לפי שובר' ? post['כמות'] : 1)}</p>
                            </>
                          )}
                        </>)
                }
              </div>
            ))}
          {disclick === true ? <button type="submit" disabled style={{ backgroundColor: "gray" }}>בשליחה...</button> :
            <button type="submit" >שמור</button>
          }

        </form>
      </div>
    </>
  );
}
function convertArrayToObject(arr) {
  // יצירת מערך ריק לכל האובייקטים
  let result = [];

  // עבר בכל תת מערך שבמערך הנתון והמרתו לאובייקט
  for (let i = 0; i < arr.length; i++) {
    let subArray = arr[i];
    let label = subArray["שם הקבוצה"]// הסרת רווחים משני הצדדים של השם
    let value = subArray.id

    //   יצירת אובייקט חדש והוספתו למערך
    result.push({ value, label });
  }

  return result;
}
export default Add;
